<template>
	<v-sheet :class="{ 'internal-table-listing': internal }">
		<div v-if="pageLoading" class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>

		<template v-else-if="tbody.length == 0 && pageLoading == false">
			<p class="m-0 row-not-found text-center">
				<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
				Uhh... There are no {{ type }} at the moment.
			</p>
		</template>

		<template v-else>
			<v-simple-table class="bt-table">
				<template v-slot:default>
					<thead>
						<tr>
							<!-- :style="{
									position: key < 2 ? 'sticky' : '',
									left: key < 2 ? (key == 0 ? 0 : key == 1 ? '48px' : '0') : '',
									zIndex: key < 2 ? '999999999' : 0,
								}" -->
							<th v-for="(th, key) in sortedThead" :key="key" :width="th.width">
								<v-layout>
									<v-flex class="text-left">
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												v-model="checkbox"
												hide-details
												class="m-0 p-0"
												v-on:change="selectAll"
												:indeterminate="indeterminate"
											></v-checkbox>
										</template>
										<template v-else>
											<div @dblclick="headClick(th)" class="text-wrap d-flex">
												{{ th.name }}
											</div>
										</template>
									</v-flex>
									<v-flex
										v-if="th.key == 'reminder' || th.type == 'lead' || th.type == 'meeting'"
										class="text-right ml-2"
									>
										<template v-if="th.sort == 'ASC'">
											<v-icon small color="blue" @click="sortTableAccColumn('DESC', th)" link
												>mdi-sort-ascending</v-icon
											>
										</template>
										<template v-if="th.sort == 'DESC'">
											<v-icon small color="blue" @click="sortTableAccColumn('ASC', th)" link>
												mdi-sort-descending
											</v-icon>
										</template>
									</v-flex>
								</v-layout>
							</th>
						</tr>
					</thead>
					<tbody>
						<!-- :style="{
												position: hkey < 2 ? 'sticky' : '',
												left: hkey < 2 ? (hkey == 0 ? 0 : hkey == 1 ? '48px' : '0') : '',
												zIndex: hkey < 2 ? '999999999' : 0,
												backgroundColor: hkey < 2 ? 'white' : 'transparent',
											}" -->

						<template v-for="(td, bkey) in tbody">
							<v-hover :key="`row-hover-${bkey}`">
								<tr :key="`row-${bkey}`">
									<template v-for="(th, hkey) in sortedThead">
										<td
											:key="`first-${hkey}-${bkey}`"
											:width="th.width"
											v-on:click="routeToDetail(th.order, td.id, th.type, td, th.key)"
											:class="{
												'td-link': th.order > 1 && th.order != 6 && th.order != 2 && th.type == 'customer',
											}"
										>
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													v-model="selectedRows"
													:value="td.id"
													hide-details
													class="m-0 p-0"
													multiple
													:disabled="type == 'leave' && td.status == 'Rejected'"
													@change="updateSelected"
												>
												</v-checkbox>
											</template>
											<template v-else-if="th.key == 'action'">
												<div class="d-flex">
													<template v-if="getPermission(`${type}:update`)">
														<v-tooltip top content-class="custom-top-tooltip" v-if="type != 'leave'">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	:disabled="getDisabled(td, th)"
																	fab
																	dark
																	x-small
																	color="green"
																	v-bind="attrs"
																	v-on="on"
																	class="mx-3 custom-disabled"
																	v-on:click.stop.prevent="routeToUpdate(td.id)"
																>
																	<v-icon>mdi-pencil</v-icon>
																</v-btn>
															</template>
															<span>Edit</span>
														</v-tooltip>
														<v-tooltip top content-class="custom-top-tooltip" v-if="type == 'leave'">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	fab
																	dark
																	x-small
																	color="red"
																	class="mr-3 custom-disabled-del"
																	v-bind="attrs"
																	v-on="on"
																	v-on:click.stop.prevent="deleteConfirm(td)"
																>
																	<v-icon>mdi-delete</v-icon>
																</v-btn>
															</template>
															<span>Delete</span>
														</v-tooltip>
													</template>

													<template v-if="th.type == 'lead'">
														<div class="fw-500 align-center cursor-pointer d-flex">
															<!-- mdi-pin  mdi-pin-outline -->
															<div class="">
																<!-- {{ td.pinned }} -->
																<v-tooltip top class="pa-1 rotate--20deg" content-class="custom-top-tooltip">
																	<template #activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			color="red"
																			class="rotate--20deg"
																			v-model="td.pinned"
																			@click.prevent.stop="leadPinned(td.id, td.status, 'pinned', !td.pinned)"
																			>{{ td.pinned ? "mdi-pin" : "mdi-pin-outline" }}
																		</v-icon>
																	</template>
																	<span>{{ td.pinned ? "Unpin" : "Pin" }}</span>
																</v-tooltip>
																<!-- <v-checkbox
																	hide-details
																	v-model="td.pinned"
																	class="ma-1"
																	:ripple="false"
																	color="red"
																	@click.prevent.stop="leadPinned(td.id, td.status, 'pinned', td.pinned)"
																></v-checkbox> -->
															</div>
															<div class="">
																<v-tooltip top class="pa-1 rotate--20deg" content-class="custom-top-tooltip">
																	<template #activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			color="blue"
																			class="rotate--20deg"
																			v-model="td.pin"
																			@click.prevent.stop="leadPinned(td.id, td.status, 'pin', !td.pin)"
																			>{{ td.pin ? "mdi-pin" : "mdi-pin-outline" }}
																		</v-icon>
																	</template>
																	<span>{{ td.pin ? "Unpin" : "Pin" }}</span>
																</v-tooltip>
																<!-- <v-checkbox
																	hide-details
																	v-model="td.pin"
																	class="ma-1"
																	:ripple="false"
																	color="blue"
																	@click.prevent.stop="leadPinned(td.id, td.status, 'pin', td.pin)"
																></v-checkbox> -->
															</div>
														</div>
													</template>

													<template v-if="th.type == 'notification'">
														<span class="fw-500 text-end w-20 cursor-pointer d-flex">
															<div class="mr-1 mt-n1">
																<div
																	v-if="td.is_marked == '0' || td.is_marked == 0"
																	class="name text-capitalize"
																	style="background: red; color: white; padding: 1px 5px; font-size: 12px"
																	v-on:click="mark_as_read(td.id)"
																>
																	Mark as Read
																</div>

																<div
																	v-else
																	class="name text-capitalize"
																	style="
																		background: #0080008f;
																		color: white;
																		padding: 1px 2px;
																		font-size: 10px;
																		cursor: default;
																	"
																>
																	read
																</div>
															</div>
														</span>
													</template>
												</div>
												<!-- <v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-btn depressed fab dark
															:disabled="td.status == 'Approved' || td.status == 'Rejected' ? true : false"
															x-small color="red" class="mr-3 custom-disabled-del"
															v-bind="attrs" v-on="on"
															v-on:click.stop.prevent="deleteConfirm(td)">
															<v-icon>mdi-delete</v-icon>
														</v-btn>
													</template>
													<span>Delete</span>
												</v-tooltip> -->
											</template>
											<template v-else-if="th.key == 'start_date' && th.type == 'invoice'">
												<div class="mb-1">
													<template>
														<v-chip
															label
															color="red white--text"
															outlined
															text-color=""
															x-small
															v-if="td && td.start_date"
														>
															<span class="fw-500" v-if="td && td.start_date"> {{ addDate(td.start_date) }}</span>
														</v-chip>
													</template>
												</div>
												<div>
													<v-chip
														label
														color="green white--text"
														outlined
														text-color=""
														x-small
														v-if="td && td.start_date"
													>
														<span class="fw-500" v-if="td && td.start_date">
															{{ formatDate(td.start_date) }}</span
														>
													</v-chip>
												</div>
											</template>

											<template v-else-if="th.key == 'leave_start_date'">
												<span v-if="td.leave_start_date">{{ formatDate(td.leave_start_date) }}</span>
											</template>
											<template v-else-if="th.key == 'holiday_start_date'">
												<span v-if="td.start_date">{{ formatDate(td.start_date) }}</span>
											</template>
											<template v-else-if="th.key == 'actual_date'">
												<template v-if="td.actual_date">
													<v-chip
														label
														color="red white--text"
														outlined
														text-color=""
														x-small
														v-if="td && td.actual_date && getDate(td) > 0"
													>
														<span class="fw-500" v-if="td && td.actual_date">
															{{ formatDate(td.actual_date) }}</span
														>
													</v-chip>
													<v-chip
														label
														color="green white--text"
														outlined
														text-color=""
														x-small
														v-if="td && td.actual_date && (getDate(td) == 0 || getDate(td) < 0)"
													>
														<span class="fw-500" v-if="td && td.actual_date">
															{{ formatDate(td.actual_date) }}</span
														>
													</v-chip>
												</template>
												<span v-else> -- </span>
											</template>
											<template v-else-if="th.key == 'provision_date' && th.type == 'leave'">
												<span v-if="td.provision_date"
													>{{ formatDate(td.joining_date) }} <b> To</b> {{ formatDate(td.provision_date) }}</span
												>
												<span v-else>--</span>
											</template>
											<template
												v-else-if="th.key == 'cost' && (th.type == 'project' || th.type == 'contract')"
											>
												<Chip
													v-if="td.cost"
													class="mr-1"
													outlined
													:text="formatMoney(td.cost)"
													text-color=""
													color="green"
												></Chip>
												<em v-else class="fw-500 text-muted">no cost</em>
											</template>
											<template v-else-if="th.key == 'joining_date' && th.type == 'leave'">
												<span v-if="td.joining_date">{{ formatDate(td.joining_date) }}</span>
												<!-- <template v-if="td && td.duration">
														<span class="fw-600 pr-2">Half Day : </span>
														<v-chip
															class="mb-1 mr-1"
															label
															:color="`${td.status.status_color} white--text`"
															x-small
															v-if="td && td.duration_type == 1"
														>
															<template v-if="td.day_type == 1">
																{{ formatDate(td.start_date) }}
															</template>
															<template v-else>
																{{ formatDate(td.end_date) }}
															</template>

															(First Half)
														</v-chip>
														<v-chip
															class="mb-1 mr-1"
															label
															:color="`${td.status.status_color} white--text`"
															x-small
															v-else
														>
															<template v-if="td.day_type == 1">
																{{ formatDate(td.start_date) }}
															</template>
															<template v-else> {{ formatDate(td.end_date) }} </template>(Second Half)
														</v-chip>
														
													</template> -->
											</template>
											<template v-else-if="th.key == 'date'">
												<span v-if="td.date">{{ formatDate(td.date) }}</span>
											</template>
											<template v-else-if="th.key == 'reason' && th.type == 'leave'">
												<div
													class="text-truncate"
													style="white-space: wrap; text-wrap: wrap; width: 350px; text-align: justify"
													v-if="td.reason"
												>
													{{ td.reason }}
												</div>
												<em v-else class="text-muted"> no reason</em>
											</template>
											<template v-else-if="th.key == 'application_date' && th.type == 'leave'">
												<div v-if="td.application_date">{{ formatDate(td.application_date) }}</div>
												<em v-else class="text-muted"> no application date</em>
											</template>
											<template v-else-if="th.key == 'holiday_end_date'">
												<span v-if="td.end_date">{{ formatDate(td.end_date) }}</span>
											</template>
											<template v-else-if="th.key == 'expense_date'">
												<span v-if="td.expense_date">{{ formatDate(td.expense_date) }}</span>
											</template>
											<template v-else-if="th.key == 'joining_date'">
												<span v-if="td.joining_date">{{ formatDate(td.joining_date) }}</span>
											</template>
											<template v-else-if="th.key == 'extend_count'">
												<span v-if="td.extend_count > 0">
													<v-avatar color="red" size="27" class="mb-2">
														<span class="white--text fw-700"> E {{ td.extend_count }}</span>
													</v-avatar>
													<div v-if="td.extend_time_period == 'month'">
														<v-chip class="mb-1 mr-1" label color="black" x-small outlined text-color="">
															{{ td.extend_duration }} (Months)
														</v-chip>
													</div>
													<div v-if="td.extend_time_period == 'day'">
														<v-chip class="mb-1 mr-1" label color="black" x-small outlined text-color="">
															{{ td.extend_duration }} (Days)
														</v-chip>
													</div>
												</span>
												<em v-else class="text-muted"> no extension</em>
												<div v-if="td.invoice_priod_month == 1">
													<Chip text="Monthly" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</div>
												<div v-if="td.invoice_priod_month == 3">
													<Chip text="Quarterly" color="orange" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</div>
												<div v-if="td.invoice_priod_month == 6">
													<Chip text="Half Yearly" color="red" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</div>
												<div v-if="td.invoice_priod_month == 12">
													<Chip text="Yearly" color="green" outlined text-color="" class="mr-1 mb-1"> </Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'provision_date'">
												<span v-if="td.joining_date"
													>{{ formatDate(td.joining_date) }} <b>To</b> {{ formatDate(td.provision_date) }}</span
												>
											</template>
											<template v-else-if="th.key == 'remark' && th.type == 'contract'">
												<div style="max-width: 20rem" class="text-truncate">
													<span v-if="td.remark">{{ td.remark }}</span>
													<em v-else class="text-muted"> no remark</em>
												</div>
											</template>
											<template v-else-if="th.key == 'holiday_type'">
												<span class="text-capitalize" v-if="td.holiday_type">{{
													td.holiday_type.replace("_", " ")
												}}</span>
											</template>
											<template v-else-if="th.key == 'expense_category'">
												<v-chip
													label
													text-color=""
													color="blue white--text"
													x-small
													v-if="td && td.expense_category"
												>
													<span class="fw-500" v-if="td && td.expense_category">{{
														td.expense_category
													}}</span> </v-chip
												><br />
												<template v-if="td.sub_category">
													{{ td.sub_category }}
												</template>
											</template>
											<template v-else-if="th.key == 'category' && th.type == 'leave'">
												<v-chip
													v-if="td && td.category"
													:color="td.category == 'UnPaid' ? 'red' : 'blue'"
													class="px-2"
													outlined
												>
													{{ td.category }}
												</v-chip>
												<!-- <span v-if="getProvision(td.provision_date) >= 0"> (Unpaid)</span> -->
											</template>
											<template v-else-if="th.key == 'amount' && th.type == 'lead'">
												<v-chip label color="green white--text" x-small v-if="td && td.amount">
													<span class="fw-500" v-if="td && td.amount">
														{{ formatMoney(td.amount) }}
													</span>
												</v-chip>
												<em v-else class="text-muted"> no amount</em>
											</template>
											<template v-else-if="th.key == 'source' && th.type == 'customer'">
												<Chip
													class="text-capitalize mb-1"
													text-color=""
													outlined
													:text="changeStatus(td.source)"
													color="blue"
												></Chip>
											</template>
											<template v-else-if="th.key == 'source' && th.type == 'lead'">
												<span v-if="td.source && th.type == 'lead'">
													<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
														<v-menu
															offset-y
															min-width="100px"
															style="padding: 0px 4px"
															rounded
															bottom
															content-class="rounded-lg"
															nudge-left="144"
														>
															<template v-slot:activator="{ on, attrs }">
																<div
																	v-bind="attrs"
																	v-on="on"
																	class="d-flex justify-space-between align-center"
																	:style="taskStatusBorder(td, 'source')"
																	style="
																		padding: 0px 6px;
																		background-color: #f7faff;
																		width: 120px;
																		border-radius: 9px;
																	"
																>
																	<span
																		class="text-capitalize font-level-1"
																		:class="taskStatusColor(td, 'source')"
																		>{{ td.source.replace("-", " ") }}</span
																	>
																	<v-icon color="blue" style="font-size: 22px" small>
																		{{ attrs && attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down" }}
																	</v-icon>
																	<!-- <inline-svg fill="#808080" v-bind="attrs" v-on="on"
																			:src="$assetURL('media/svg/expand-more.svg')" /> -->
																</div>
															</template>

															<v-list elevation>
																<v-list-item
																	v-on:change="updateOpportunity(item.value, td.id, 'source', bkey, item?.text)"
																	class="list-item cursor-pointer p-1"
																	v-for="(item, index) in sourceList"
																	:key="index"
																>
																	<div class="py-1 px-3 text-uppercase fw-600">{{ item?.text }}</div>
																</v-list-item>
															</v-list>
														</v-menu>
													</div>
												</span>
												<em v-else class="text-muted">no source</em>

												<span v-if="td.product_type">
													<div v-on:click.stop class="d-flex justify-space-between align-center">
														<v-menu
															offset-y
															min-width="100px"
															nudge-left="52"
															style="padding: 0px 4px"
															content-class="rounded-lg"
															rounded
														>
															<template #activator="{ on, attrs }">
																<div
																	v-bind="attrs"
																	v-on="on"
																	class="d-flex justify-space-between align-center"
																	:style="taskStatusBorder(td, 'product_type')"
																	style="
																		padding: 0px 6px;
																		background-color: #f7faff;
																		width: 120px;
																		border-radius: 9px;
																	"
																>
																	<span
																		class="text-capitalize font-level-1"
																		:class="taskStatusColor(td, 'product_type')"
																		>{{ td.product_type.replace("-", " ") }}</span
																	>
																	<v-icon color="green" style="font-size: 22px" small>
																		{{ attrs && attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down" }}
																	</v-icon>
																</div>
															</template>

															<v-list elevation>
																<v-list-item
																	v-on:change="
																		updateOpportunity(item.value, td.id, 'product_type', bkey, item?.text)
																	"
																	class="list-item cursor-pointer p-1"
																	v-for="(item, index) in productList"
																	:key="index"
																>
																	<div class="py-1 px-3 text-uppercase fw-600">{{ item?.text }}</div>
																</v-list-item>
															</v-list>
														</v-menu>
													</div>
												</span>
											</template>
											<template v-else-if="th.key == 'customer' && th.type == 'claim'">
												<div v-if="td.customer_barcode">
													<!-- <div>
														<label class="font-size-14 mb-1 mr-1"
															><b>
																<v-icon size="18">mdi-pound</v-icon>
															</b></label
														>
														<span v-if="td.customer_barcode">{{ td.customer_barcode }} </span>
													</div> -->
													<div>
														<label class="font-size-14 mb-1 mr-1"
															><b>
																<v-icon size="18">mdi-account-circle</v-icon>
															</b></label
														>
														<span v-if="td.customer_company_name">{{ td.customer_company_name }}</span>
														<em v-else class="text-muted">no company name</em>
													</div>
													<!-- <div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account</v-icon>
														</b></label
													>
													<span v-if="td.user_name">
														<v-chip
															class="mb-1 mr-1"
															small
														>
															<v-avatar left small>
																<img v-if="td.profile_img" :src="td.profile_img" />
																<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
															</v-avatar>
															<span v-if="td && td.user_name">{{ td.user_name }}</span>
														</v-chip>	
													</span>
													<em v-else class="text-muted">no sales person</em>
												</div> -->
												</div>
												<em class="text-muted" v-else>no company detail</em>
											</template>
											<template v-else-if="th.key == 'contract_start_date'">
												<span v-if="td.contract_start_date">
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Start Date : </b></label>
														<span v-if="td.contract_start_date">{{ formatDate(td.contract_start_date) }} </span>
													</div>
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> End Date : </b></label>
														<span v-if="td.contract_end_date">{{ formatDate(td.contract_end_date) }}</span>
													</div>
												</span>
											</template>
											<template v-else-if="th.key == 'sales_date'">
												<span v-if="td.sales_date">
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Sales Date : </b></label>
														<span v-if="td.sales_date">{{ formatDate(td.sales_date) }} </span>
													</div>
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Invoice Date : </b></label>
														<span v-if="td.invoice_date">{{ formatDate(td.invoice_date) }}</span>
													</div>
												</span>
											</template>
											<!-- <template v-else-if="th.key == 'billing_type'">
												<span v-if="td.billing_type == 'single_billing'">
													<Chip class="mr-1" outlined text="Single Billing" text-color="" color="blue"></Chip>
												</span>
												<span v-else>
													<Chip
														class="mr-1"
														outlined
														text="Multiple Billing"
														text-color=""
														color="orange"
													></Chip>
												</span>
											</template> -->
											<template v-else-if="th.key == 'status' && th.type == 'task'">
												<v-chip
													class="mb-1 mr-1"
													label
													:color="`${getStatusColor(td.status)} white--text`"
													x-small
												>
													{{ getStatusText(td.status) }}
												</v-chip>
											</template>

											<template v-else-if="th.key == 'type' && th.type == 'project'">
												<!-- <v-chip class="mb-1 mr-1 text-capitalize" label color="blue white--text" x-small>
													{{ td.type }}
												</v-chip> -->

												<div class="d-flex flex-wrap" style="max-width: 150px; min-width: 150px">
													<template v-if="td.seo > 0">
														<Chip text="SEO" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
													</template>
													<template v-if="td.domain > 0">
														<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.web > 0">
														<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.ssl > 0">
														<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.hostting > 0">
														<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.maintenance > 0">
														<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.software > 0">
														<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1"> </Chip>
													</template>
												</div>
											</template>

											<template v-else-if="th.key == 'customer_barcode' && th.type == 'project'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-pound</v-icon>
														</b></label
													>
													<span v-if="td.customer_barcode">{{ td.customer_barcode }} </span>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account-circle</v-icon>
														</b></label
													>
													<span v-if="td.customer_company_name">{{ td.customer_company_name }}</span>
													<em v-else class="text-muted">no company name</em>
												</div>
												<!-- <div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account</v-icon>
														</b></label
													>
													<span v-if="td.user_name">
														<v-chip
															class="mb-1 mr-1"
															small
														>
															<v-avatar left small>
																<img v-if="td.profile_img" :src="td.profile_img" />
																<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
															</v-avatar>
															<span v-if="td && td.user_name">{{ td.user_name }}</span>
														</v-chip>	
													</span>
													<em v-else class="text-muted">no sales person</em>
												</div> -->
											</template>

											<template v-else-if="th.key == 'customer_barcode' && th.type == 'invoice'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-pound</v-icon>
														</b></label
													>
													<span v-if="td.customer_barcode">{{ td.customer_barcode }} </span>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-web</v-icon>
														</b></label
													>
													<span v-if="td.customer_company_name">{{ td.customer_company_name }}</span>
													<em v-else class="text-muted">no name</em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account-circle</v-icon>
														</b></label
													>
													<span class="fw-500" v-if="td && td.attendies">
														<v-chip class="mb-1 mr-1" small v-for="(data, index) in td.attendies" :key="index">
															<v-avatar left small>
																<img
																	v-if="data && (data.image || data.profile_img)"
																	:src="data.image || data.profile_img"
																/>
																<img
																	v-else
																	src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																/>
															</v-avatar>
															<span v-if="data && data.display_name"
																>{{ data.display_name }} {{ data.profile_img }}</span
															>
														</v-chip>
													</span>
													<em v-else class="text-muted">no sales person</em>
												</div>
											</template>

											<template v-else-if="th.key == 'contract_barcode' && th.type == 'project'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-pound</v-icon>
														</b></label
													>
													<span v-if="td.contrct_barcode">{{ td.contrct_barcode }} </span>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-text</v-icon>
														</b></label
													>
													<span v-if="td.project_title">{{ td.project_title }}</span>
													<em v-else class="text-muted">no title</em>
												</div>
											</template>
											<!-- <template v-else-if="th.key == 'barcode' && th.type == 'project'">
												<div class="mb-2">
													<label class="font-size-14 mb-1 mr-5"><b> Project # : </b></label>
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-2"><b> Contract # : </b></label>
													<Chip :text="td.contrct_Bracode" color="orange" v-if="td.contrct_Bracode"></Chip>
												</div>
											</template> -->

											<template v-else-if="th.key == 'status' && th.type == 'project'">
												<div class="mb-2">
													<Chip :text="td.status" color="green" v-if="td.status == 'Completed'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip text="Completed" color="green" v-if="td.status == 'Renewed'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Draft'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="red" v-if="td.status == 'Cancelled'"> </Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'type' && th.type == 'invoice'">
												<!-- <div v-if="td.project_billing_type == 'single_billing'">
													<Chip text="Single Billing" color="blue" outlined text-color="" class="mr-1 mb-1">
													</Chip>
												</div>
												<div v-if="td.project_billing_type == 'multiple_billing'">
													<Chip text="Multiple Billing" color="blue" outlined text-color="" class="mr-1 mb-1">
													</Chip>
												</div> -->
												<div class="d-flex flex-wrap" style="max-width: 200px; min-width: 200px">
													<template v-if="td.seo > 0">
														<Chip text="SEO" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
													</template>
													<template v-if="td.domain > 0">
														<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.web > 0">
														<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.ssl > 0">
														<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.hostting > 0">
														<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.maintenance > 0">
														<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.software > 0">
														<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1"> </Chip>
													</template>
												</div>
											</template>

											<template v-else-if="th.key == 'checkbox_type' && th.type == 'contract'">
												<div class="d-flex flex-wrap" style="max-width: 200px; min-width: 200px">
													<template v-if="td.checkbox_type.includes('seo')">
														<Chip text="SEO" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('domain')">
														<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('web')">
														<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('ssl')">
														<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('hosting')">
														<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('maintenance')">
														<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('software')">
														<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1"> </Chip>
													</template>
												</div>
											</template>

											<template v-else-if="th.key == 'checkbox_type' && th.type == 'project'">
												<div class="d-flex flex-wrap" style="max-width: 150px; min-width: 150px">
													<template v-if="td.checkbox_type.includes('seo')">
														<Chip text="SEO" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('domain')">
														<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('web')">
														<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('ssl')">
														<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('hosting')">
														<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('maintenance')">
														<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="td.checkbox_type.includes('software')">
														<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1"> </Chip>
													</template>
												</div>
											</template>

											<template v-else-if="th.key == 'status' && th.type == 'contract'">
												<div class="mb-2">
													<Chip :text="td.status" color="green" v-if="td.status == 'Accepted'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="red" v-if="td.status == 'Rejected'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Draft'"> </Chip>
												</div>
											</template>

											<template v-else-if="th.key == 'barcode' && th.type == 'customer'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
												<div class="mb-2" v-if="td.lead_barcode">
													<Chip
														:text="td.lead_barcode"
														text-color=""
														outlined
														color="red"
														v-if="td.lead_barcode"
													></Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'barcode' && th.type == 'user'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
												<div class="email mt-1 fw-500" v-if="getProvision(td.provision_date) >= 0">
													<v-chip
														v-if="getProvision(td.provision_date) >= 0"
														color="red"
														class="white--text"
														outlined
														style="letter-spacing: 0.8px"
														small
													>
														Probation
													</v-chip>
												</div>
												<!-- <div class="mb-2" v-if="td.lead_barcode">
													<Chip :text="td.lead_barcode" text-color="" outlined color="red"
														v-if="td.lead_barcode"></Chip>
												</div> -->
											</template>

											<template v-else-if="th.key == 'barcode' && th.type == 'contract'">
												<div style="max-width: 10rem">
													<div class="mb-2 d-flex justify-content-between">
														<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
													</div>
													<div v-if="td.convert_project == 1">
														<Chip
															text="Project Created"
															text-color=""
															outlined
															color="red"
															v-if="td.convert_project"
														></Chip>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'barcode' && th.type == 'project'">
												<!-- class="d-flex align-center justify-content-between" -->
												<div style="max-width: 8rem !important">
													<div class="mr-1">
														<div
															class="d-flex justify-content-between"
															:class="{ 'mb-1': td.status == 'Renewed' }"
														>
															<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
														</div>
														<!-- :class="{ 'mb-2': td.renewed > 0 }" -->
														<div v-if="td.status == 'Renewed'">
															<Chip text="Renewed" color="orange" text-color="" outlined></Chip>
														</div>
													</div>
													<div style="max-width: fit-content" class="mt-1" v-if="td.renewed > 0">
														<span class="mb-2">
															<v-avatar color="red" size="27">
																<span class="white--text fw-600"> R {{ td.renewed }}</span>
															</v-avatar>
														</span>
													</div>
												</div>

												<!-- <span v-if="td.status == 'Draft' && td.renewed == 0" class="mb-2">
														<v-avatar color="red" size="35" class="mr-2">
															<span class="white--text fw-600" style="font-size: 18px"> R </span>
														</v-avatar>
													</span> -->
											</template>

											<template v-else-if="th.key == 'entity_type' && th.type == 'project'">
												<div>
													<div
														style="max-width: 11rem"
														v-if="_getValue(td, 'entity_type')"
														class="fw-600 pa-1 pl-2 rounded-sm text-capitalize mt-1"
													>
														<img
															style="max-width: 25px"
															:src="
																td.entity_type == 'bthrust'
																	? $assetURL('media/project-logos/bthrust-icon.png')
																	: $assetURL('media/project-logos/genic.png')
															"
															alt="Entity"
														/>
														{{ _getValue(td, "entity_type").split("-").join(" ") }}
													</div>
													<em v-else>no entity</em>
												</div>
											</template>
											<template v-else-if="th.key == 'entity_type' && th.type == 'contract'">
												<div>
													<div
														style="max-width: 11rem"
														v-if="_getValue(td, 'entity_type')"
														class="fw-600 pa-1 pl-2 rounded-sm text-capitalize mt-1"
													>
														<img
															style="max-width: 25px"
															:src="
																td.entity_type == 'bthrust'
																	? $assetURL('media/project-logos/bthrust-icon.png')
																	: $assetURL('media/project-logos/genic.png')
															"
															alt="Entity"
														/>
														{{ _getValue(td, "entity_type").split("-").join(" ") }}
													</div>
													<em v-else>no entity</em>
												</div>
											</template>
											<template v-else-if="th.key == 'barcode' && th.type == 'lead'">
												<div class="mb-1">
													<Chip :text="td.barcode" class="mr-2" color="cyan" v-if="td.barcode"> </Chip>
												</div>
												<span v-if="td.status">
													<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
														<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
															<template v-slot:activator="{ on, attrs }">
																<div
																	v-bind="attrs"
																	v-on="on"
																	class="d-flex justify-space-between align-center"
																	:style="{
																		border: `1px solid ${leadStatusColor(leadStatus(td.status))?.border}`,
																		background: `${leadStatusColor(leadStatus(td.status))?.border}`,
																	}"
																	style="
																		padding: 0px 6px;
																		background-color: #f7faff;
																		width: 120px;
																		border-radius: 3px;
																	"
																>
																	<span class="text-capitalize white--text font-level-1">
																		{{ leadStatus(td.status) }}
																	</span>
																	<v-icon
																		class="text-white"
																		style="font-size: 22px"
																		:color="taskStatusColor(td, 'status')"
																		small
																	>
																		mdi-menu-down
																	</v-icon>
																</div>
															</template>

															<v-list elevation>
																<v-list-item
																	v-on:change="updateOpportunity(item.value, td.id, 'status', bkey, item?.text)"
																	class="list-item cursor-pointer p-1"
																	v-for="(item, index) in statusList"
																	:key="index"
																>
																	<v-list-item-title class="pl-4">
																		<div class="d-flex">
																			<div
																				:style="{
																					backgroundColor: leadStatusColor(item?.text)?.border,
																					height: '15px',
																					width: '15px',
																					borderRadius: '50%',
																				}"
																			></div>
																			<!-- {{ leadStatusColor(item.text) }} -->
																			&nbsp;
																			<div>
																				{{ item?.text }}
																			</div>
																		</div>
																	</v-list-item-title>
																</v-list-item>
															</v-list>
														</v-menu>
													</div>
												</span>

												<span>
													<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
														<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
															<template #activator="{ on, attrs }">
																<div
																	v-bind="attrs"
																	v-on="on"
																	class="d-flex justify-space-between align-center"
																	:style="{ border: `1px solid ${leadStatusColor(td.opportunity)?.border}` }"
																	style="
																		padding: 0px 6px;
																		background-color: #f7faff;
																		width: 130px;
																		border-radius: 9px;
																	"
																>
																	<span
																		class="text-capitalize font-level-1"
																		:class="leadStatusColor(td.opportunity)?.text + '--text'"
																		>{{ td.opportunity ? td.opportunity.replace("-", " ") : "No Opportunity" }}</span
																	>
																	<v-icon style="font-size: 22px" :color="taskStatusColor(td, 'opportunity')" small
																		>mdi-menu-down</v-icon
																	>
																	<!-- <inline-svg fill="#808080" v-bind="attrs" v-on="on"
																			:src="$assetURL('media/svg/expand-more.svg')" /> -->
																</div>
															</template>

															<v-list elevation>
																<v-list-item
																	v-on:change="updateOpportunity(item.value, td.id, 'opportunity', bkey, item?.text)"
																	class="list-item cursor-pointer p-1"
																	v-for="(item, index) in opportunityList"
																	:key="index"
																>
																	<v-list-item-title class="pl-4">
																		<div class="d-flex">
																			<div
																				:style="{
																					backgroundColor: leadStatusColor(item.value)?.border,
																					height: '15px',
																					width: '15px',
																					borderRadius: '50%',
																				}"
																			></div>
																			&nbsp;
																			<div>{{ item?.text }}</div>
																		</div>
																	</v-list-item-title>
																</v-list-item>
															</v-list>
														</v-menu>
													</div>
												</span>
												<!-- <div class="mb-2">
													<Chip text="Duplicated" class="mr-2" text-color="" color="blue" outlined v-if="td.is_duplicate == 1">
													</Chip>
												</div>
                                                <div class="mb-2">
													<Chip :text="td.duplicate_barcode" class="mr-2" text-color="" color="black" outlined v-if="td.duplicate > 0">
													</Chip>
												</div> -->
												<div class="mb-2" v-if="td.is_converted == 1">
													<Chip
														text="Customer Created"
														text-color=""
														outlined
														color="red"
														v-if="td.is_converted"
													></Chip>
												</div>
											</template>
											<!-- <template v-else-if="th.key == 'barcode' && th.type == 'meeting'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
											</template> -->
											<template v-else-if="th.key == 'barcode' && th.type == 'task'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'barcode' && th.type == 'leave'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'barcode' && th.type == 'claim'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
												<div class="mb-2">
													<v-chip
														class="text-capitalize"
														:color="getcategoryColor(td.category)"
														x-small
														label
														v-if="td && td.category"
														><template v-if="td.category == 'owan'"> Own </template>
														<template v-else>
															{{ td.category.replace("-", " ") }}
														</template>
													</v-chip>
												</div>
											</template>
											<template v-else-if="th.key == 'category' && th.type == 'claim'">
												<div class="mb-2">
													<v-chip
														class="text-capitalize"
														:color="getcategoryColor(td.category)"
														x-small
														label
														v-if="td && td.category"
														><template v-if="td.category == 'owan'"> Own </template>
														<template v-else>
															{{ td.category.replace("-", " ") }}
														</template>
													</v-chip>
												</div>
											</template>
											<template v-else-if="th.key == 'billable_name' && th.type == 'claim'">
												<div class="text-h6 fw-500">
													{{ td.billable_name }}
												</div>
											</template>
											<template v-else-if="th.key == 'barcode' && th.type == 'holiday'">
												<div class="mb-2">
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
												</div>
											</template>

											<template v-else-if="th.key == 'customer_barcode' && th.type == 'contract'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-pound</v-icon>
														</b></label
													>
													<span v-if="td.customer_barcode">{{ td.customer_barcode }} </span>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account-circle</v-icon>
														</b></label
													>
													<span v-if="td.customer_company_name">{{ td.customer_company_name }}</span>
													<em v-else class="text-muted">no company name</em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account</v-icon>
														</b></label
													>
													<span v-if="td.attendies && td.attendies.length && td.attendies.length > 0">
														<template v-for="(row, index) in td.attendies">
															<span v-if="index < 3" :key="index + 'avatar'">
																<v-tooltip top content-class="custom-top-tooltip">
																	<template v-slot:activator="{ on, attrs }">
																		<v-chip class="mb-1 mr-1" small v-on="on" v-bind="attrs">
																			<v-avatar left small>
																				<img v-if="row.image" :src="row.image" />
																				<img
																					v-else
																					src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																				/>
																			</v-avatar>
																			<span v-if="row && row.display_name">{{ row.display_name }}</span>
																		</v-chip>
																	</template>
																	<span>{{ row.display_name }}</span>
																</v-tooltip>
															</span>
															<span v-if="index == 3" :key="index + 'more'">
																<v-tooltip top content-class="custom-top-tooltip">
																	<template v-slot:activator="{ on, attrs }">
																		<v-avatar
																			v-on="on"
																			v-bind="attrs"
																			size="30"
																			style="border: 1px solid grey !important"
																			class="ml-n3 white"
																		>
																			<span> + {{ td.attendies.length - 3 }} </span>
																		</v-avatar>
																	</template>
																	<span>{{ td.attendies.length - 3 }} More</span>
																</v-tooltip>
															</span>
														</template>
														<!-- <v-chip class="mb-1 mr-1" small>
															<v-avatar left small>
																<img v-if="td.profile_img" :src="td.profile_img" />
																<img
																	v-else
																	src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																/>
															</v-avatar>
															<span v-if="td && td.user_name">{{ td.user_name }}</span>
														</v-chip> -->
													</span>
													<em v-else class="text-muted">no sales person</em>
												</div>
											</template>
											<template v-else-if="th.key == 'project_invoice_period' && th.type == 'project'">
												<template v-if="td.project_invoice_period == 1">
													<v-chip class="mb-1 mr-1" label color="orange white--text" x-small>
														One Monthly
													</v-chip>
												</template>
												<template v-if="td.project_invoice_period == 3">
													<v-chip class="mb-1 mr-1" label color="green white--text" x-small> Quarterly </v-chip>
												</template>
												<template v-if="td.project_invoice_period == 6">
													<v-chip class="mb-1 mr-1" label color="red white--text" x-small> Half Yearly </v-chip>
												</template>
												<template v-if="td.project_invoice_period == 12">
													<v-chip class="mb-1 mr-1" label color="gray white--text" x-small> Yearly </v-chip>
												</template>

												<!-- <v-chip class="mb-1 mr-1 text-capitalize" label color="blue white--text" x-small>
										        	{{ td.project_invoice_period }}
										     </v-chip> -->
											</template>
											<template v-else-if="th.key == 'status' && th.type == 'leave'">
												<template v-if="td.status == 'Pending'">
													<v-chip class="mb-1 mr-1" label color="orange white--text" x-small> Pending </v-chip>
												</template>
												<template v-if="td.status == 'Approved'">
													<v-chip class="mb-1 mr-1" label color="green white--text" x-small> Approved </v-chip>
												</template>
												<template v-if="td.status == 'Rejected'">
													<v-chip class="mb-1 mr-1" label color="red white--text" x-small> Rejected </v-chip>
												</template>
											</template>

											<template v-else-if="th.key == 'customer_service_type' && th.type == 'customer'">
												<template v-if="td.customer_service_type">
													<div class="d-flex flex-wrap" style="max-width: 200px; min-width: 200px">
														<span
															v-for="(row, index) in td.customer_service_type"
															:key="index"
															class="cursor-initial"
														>
															<template v-if="row == 'seo'">
																<Chip text="SEO" color="blue" outlined text-color="" class="mb-1 mr-1"> </Chip>
															</template>
															<template v-if="row == 'hosting'">
																<Chip text="H" color="blue-grey" outlined text-color="" class="mb-1 mr-1"> </Chip>
															</template>
															<template v-if="row == 'ssl'">
																<Chip text="SSL" color="green" outlined text-color="" class="mb-1 mr-1"> </Chip>
															</template>
															<template v-if="row == 'web'">
																<Chip text="W" color="orange" outlined text-color="" class="mb-1 mr-1"> </Chip>
															</template>
															<template v-if="row == 'domain'">
																<Chip text="D" color="red" outlined text-color="" class="mb-1 mr-1"> </Chip>
															</template>
															<template v-if="row == 'maintenance'">
																<Chip text="M" color="cyan" outlined text-color="" class="mb-1 mr-1"> </Chip>
															</template>
															<template v-if="row == 'software'">
																<Chip text="S" color="blue darken-4" outlined text-color="" class="mb-1 mr-1">
																</Chip>
															</template>
														</span>
													</div>
												</template>
												<em class="text-muted" v-else>no services</em>
											</template>

											<template v-else-if="th.key == 'invoice_total' && th.type == 'customer'">
												<v-chip
													label
													color="green white--text"
													x-small
													v-if="td && td.invoice_total && td.invoice_total"
												>
													<span class="fw-500" v-if="td && td.invoice_total && td.invoice_total">
														{{ formatMoney(td.invoice_total) }}</span
													>
												</v-chip>
												<em class="text-muted" v-else>no cost</em>
											</template>
											<template v-else-if="th.key == 'status' && th.type == 'invoice'">
												<!-- <div class="mb-2">
													<Chip :text="td.status" color="blue" v-if="td.status == 'Raised'">
													</Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="green" v-if="td.status == 'Paid'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="warring" v-if="td.status == 'Unpaid'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Postponed'"> </Chip>
												</div>

												<div class="mb-2">
													<Chip :text="td.status" color="red" v-if="td.status == 'Cancelled'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Draft'"></Chip>
												</div> -->
												<!-- <div class="mb-2">
													<Chip :text="td.status" color="blue" v-if="td.status == 'Raised'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="green" v-if="td.status == 'Paid'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="orange" v-if="td.status == 'Partially Paid'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Overdue Payment'"></Chip>
												</div>
											
												<div class="mb-2">
													<Chip :text="td.status" color="red" v-if="td.status == 'Pending For Issuance'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Draft'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="red" v-if="td.status == 'Overdue For Issuance'"></Chip>
												</div> -->
												<div class="mb-2">
													<Chip :text="td.status" color="blue" v-if="td.status == 'Raised'"> </Chip>
												</div>
												<div class="mb-2">
													<Chip
														text="Pending For Issuance"
														color="red"
														v-if="td.direct_type == 'pending-issue'"
														outlined
														text-color=""
													>
													</Chip>
												</div>
												<div class="mb-2">
													<Chip
														text="Overdue For Issuance"
														color="#191970"
														v-if="td.direct_type == 'overdue-issue'"
														outlined
														text-color=""
													>
													</Chip>
												</div>
												<div class="mb-2">
													<Chip
														text="Issued"
														color="#008080"
														v-if="td.actual_date"
														outlined
														text-color=""
													></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="green" v-if="td.status == 'Paid'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="orange" v-if="td.status == 'Partially Paid'"></Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Overdue Payment'"></Chip>
												</div>

												<div class="mb-2">
													<Chip
														:text="td.status"
														color="red"
														v-if="td.status == 'Pending For Issuance'"
														outlined
														text-color=""
													>
													</Chip>
												</div>
												<div class="mb-2">
													<Chip :text="td.status" color="cyan" v-if="td.status == 'Draft'"></Chip>
												</div>
												<div class="mb-2">
													<Chip
														:text="td.status"
														color="#191970"
														v-if="td.status == 'Overdue For Issuance'"
														outlined
														text-color=""
													>
													</Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'contract_start_dates'">
												<span v-if="td.contract_start_dates">
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Start Date : </b></label>
														<span v-if="td.contract_start_dates">{{ formatDate(td.contract_start_dates) }} </span>
													</div>
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> End Date : </b></label>
														<span v-if="td.contract_end_dates">{{ formatDate(td.contract_end_dates) }}</span>
													</div>
												</span>
											</template>
											<template v-else-if="th.key == 'status' && th.type == 'meeting'">
												<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
													<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
														<template v-slot:activator="{ on, attrs }">
															<div
																v-bind="attrs"
																v-on="on"
																class="d-flex justify-space-between align-center"
																:style="{
																	border: `1px solid ${meetingStatusColor(meetingStatus(td.status))?.border}`,
																	background: `${meetingStatusColor(meetingStatus(td.status))?.border}`,
																}"
																style="padding: 0px 6px; width: 120px; border-radius: 3px"
															>
																<span class="text-capitalize white--text font-level-1">
																	{{ meetingStatus(td.status) }}
																</span>
																<v-icon
																	class="text-white"
																	style="font-size: 22px"
																	:color="meetingStatusColor(td, 'status')"
																	small
																>
																	mdi-menu-down
																</v-icon>
															</div>
														</template>

														<v-list elevation>
															<v-list-item
																v-on:change="updateMeeting(item.value, td)"
																class="list-item cursor-pointer p-1"
																v-for="(item, index) in meetingStatusList"
																:key="index"
															>
																<v-list-item-title class="pl-4">
																	<div class="d-flex">
																		<div
																			:style="{
																				backgroundColor: meetingStatusColor(item?.text)?.border,
																				height: '15px',
																				width: '15px',
																				borderRadius: '50%',
																			}"
																		></div>
																		&nbsp;
																		<div>
																			{{ item?.text }}
																		</div>
																	</div>
																</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>
											</template>
											<template v-else-if="th.key == 'status' && th.type == 'claim'">
												<template v-if="td.status == 'Rejected'">
													<v-chip class="mb-1 mr-1" label color="red white--text" x-small> Rejected </v-chip>
												</template>

												<template v-if="td.status == 'Approved'">
													<v-chip class="mb-1 mr-1" label color="green white--text" x-small> Approved </v-chip>
												</template>
												<template v-if="td.status == 'Pending'">
													<v-chip class="mb-1 mr-1" label color="orange white--text" x-small> Pending </v-chip>
												</template>
												<template v-if="td.status == 'On-Hold'">
													<v-chip class="mb-1 mr-1" label color="lime white--text" x-small> On-Hold </v-chip>
												</template>
												<template v-if="td.status == 'In-Review'">
													<v-chip class="mb-1 mr-1" label color="blue white--text" x-small> In-Review </v-chip>
												</template>
											</template>

											<template v-else-if="th.key == 'amount' && th.type == 'claim'">
												<span class="text-h5 fw-700 green--text" v-if="td && td.amount"
													><!-- ${{ td.amount }}. -->
													{{ formatMoney(td.amount) }}</span
												>
											</template>
											<template v-else-if="th.key == 'email' && th.type == 'user'">
												<span>{{ td.email }}</span>
											</template>

											<template v-else-if="th.key == 'checkbox_type'">
												<template v-if="td.checkbox_type">
													<span v-for="(row, index) in td.checkbox_type" :key="index" class="cursor-initial">
														<template v-if="row == 'seo'">
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<Chip
																		text="SEO"
																		color="blue"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>SEO</span>
															</v-tooltip>
														</template>
														<template v-if="row == 'hosting'">
															<v-tooltip top>
																<template #activator="{ on, attrs }">
																	<Chip
																		text="H"
																		color="blue-grey"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>Hosting</span>
															</v-tooltip>
														</template>
														<template v-if="row == 'ssl'">
															<v-tooltip top>
																<template #activator="{ on, attrs }">
																	<Chip
																		text="SSL"
																		color="green"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>SSL</span>
															</v-tooltip>
														</template>
														<template v-if="row == 'web'">
															<v-tooltip top>
																<template #activator="{ on, attrs }">
																	<Chip
																		text="W"
																		color="orange"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>Web</span>
															</v-tooltip>
														</template>
														<template v-if="row == 'domain'">
															<v-tooltip top>
																<template #activator="{ on, attrs }">
																	<Chip
																		text="D"
																		color="red"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>Domain</span>
															</v-tooltip>
														</template>
														<template v-if="row == 'maintenance'">
															<v-tooltip top>
																<template #activator="{ on, attrs }">
																	<Chip
																		text="M"
																		color="cyan"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>Maintenance</span>
															</v-tooltip>
														</template>
														<template v-if="row == 'software'">
															<v-tooltip top>
																<template #activator="{ on, attrs }">
																	<Chip
																		text="S"
																		color="blue darken-4"
																		v-on="on"
																		v-bind="attrs"
																		outlined
																		text-color=""
																		class="mr-1"
																	>
																	</Chip>
																</template>
																<span>Software</span>
															</v-tooltip>
														</template>
													</span>
												</template>
												<em v-else class="text-muted">no service</em>
											</template>
											<template v-else-if="th.key == 'services'">
												<span v-for="(row, index) in td.services" :key="index" class="cursor-initial">
													<span v-if="row && row.keyword">{{ row.keyword }}(Keyword) +</span
													><em v-else class="text-muted mr-1">no keyword</em><span class="ml-1"></span>
													<span v-if="row && row.free">{{ row.free }}(Free)</span
													><em v-else class="text-muted">no free</em>
													<br />
												</span>

												<!-- <span v-if="td.checkbox_type">{{ td.checkbox_type }}</span> -->
											</template>
											<template v-else-if="th.key == 'leave_end_date'">
												<span v-if="td.leave_end_date">{{ formatDate(td.leave_end_date) }}</span>
											</template>
											<template v-else-if="th.key == 'start_date'">
												<span v-if="td.start_date">{{ formatDate(td.start_date) }}</span>
											</template>
											<template v-else-if="th.key == 'profile_img'">
												<div style="width: 50px">
													<ImageTemplate circle style="max-width: 50px" :src="td.profile_img"> </ImageTemplate>
												</div>
											</template>

											<template v-else-if="th.key == 'due_date'">
												<span v-if="td.due_date">{{ formatDate(td.due_date) }}</span>
											</template>
											<template v-else-if="th.key == 'related_to'">
												<div class="d-flex align-center" :style="[{ width: `300px` }]">
													<div v-if="td.related_to == 'internal'">
														<v-tooltip top content-class="custom-top-tooltip">
															<template #activator="{ on, attrs }">
																<div v-on="on" v-bind="attrs">
																	<Chip
																		class="text-capitalize px-1"
																		x-small
																		:text="td.related_to"
																		color="red"
																	></Chip>
																</div>
															</template>
															<span class="text-capitalize">{{ td.related_to }}</span>
														</v-tooltip>
													</div>
													<div v-else>
														<div class="mb-1 d-flex align-center">
															<v-tooltip top content-class="custom-top-tooltip">
																<template #activator="{ on, attrs }">
																	<div v-on="on" v-bind="attrs">
																		<Chip
																			class="text-capitalize fw-600 px-1"
																			x-small
																			:text="(td.related_to && td.related_to[0]) || ''"
																			color="red"
																		></Chip>
																	</div>
																</template>
																<span class="text-capitalize">{{ td.related_to }}</span>
															</v-tooltip>
															<v-tooltip top content-class="custom-top-tooltip" v-if="td.related || td.relateds">
																<template #activator="{ on, attrs }">
																	<span
																		v-on="on"
																		v-bind="attrs"
																		class="dark-text ms-2 fs-16 text-truncate text-capitalize"
																		style="max-width: 195px"
																	>
																		{{ td.related }} {{ td.relateds }}
																	</span>
																</template>
																<span class="text-capitalize">{{ td.related }} {{ td.relateds }}</span>
															</v-tooltip>
															<!-- <div class="ml-2">
																<template v-if="td.lead_barcode || td.customer_barcode || td.task_barcode">
																	<v-chip class="text-capitalize px-1" label outlined x-small color="red">
																		<span class="fs-10 fw-600">
																			{{ td.lead_barcode || td.customer_barcode || td.task_barcode }}
																		</span>
																	</v-chip>
																</template>
															</div> -->
														</div>

														<!-- <div>
															<span v-if="td.lead_date" class="fw-500">{{ formatDate(td.lead_date) }}</span>
														</div> -->
														<div>
															<span
																v-if="td.lead_phone"
																class="fw-500 cursor-pointer"
																@click.stop="clickAndCopy(td.lead_phone)"
															>
																<v-icon size="16" color="green">mdi-phone</v-icon>
																{{ td.lead_phone }}
															</span>
														</div>
														<div>
															<div
																v-if="td.lead_email"
																class="fw-500 cursor-pointer text-truncate"
																@click.stop="clickAndCopy(td.lead_email)"
																style="max-width: 300px !important"
															>
																<v-icon size="16" color="primary">mdi-email</v-icon>
																{{ td.lead_email }}
															</div>
														</div>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'leave_days'">
												<span class="text-capitalize" v-if="td.leave_days">{{
													td.leave_days.replace("_", " ")
												}}</span>
											</template>
											<template v-else-if="th.key == 'staff' && th.type == 'leave'">
												<span class="text-capitalize" v-if="td.display_name">
													<v-chip small>
														<v-avatar left>
															<img v-if="td?.profile_img" :src="td?.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														{{ td.display_name }}
													</v-chip>
												</span>
												<template v-if="getProvision(td.provision_date) >= 0">
													<v-chip
														v-if="getProvision(td.provision_date) >= 0"
														color="green"
														class="px-1 ml-1"
														outlined
														x-small
													>
														P
													</v-chip>
												</template>
											</template>
											<template v-else-if="th.key == 'customers_name'">
												<span v-if="td.defaultContact">{{ td.defaultContact.name }}</span>
												<em v-else class="text-muted">no name</em>
											</template>

											<template v-else-if="th.key == 'customer_email'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account</v-icon>
														</b></label
													>
													<span v-if="td.defaultContact && td.defaultContact.name">{{
														td.defaultContact.name
													}}</span>
													<em v-else class="text-muted">no name</em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-email</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.defaultContact && td.defaultContact.email_address"
														v-on:click.prevent.stop="copyNumber(td.defaultContact.email_address)"
														>{{ td.defaultContact.email_address }}</span
													>
													<em v-else class="text-muted">no email</em>
												</div>
												<div v-if="td.defaultContact && td.defaultContact.phone_number">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-phone</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.defaultContact && td.defaultContact.phone_number"
														v-on:click.prevent.stop="copyNumber(td.defaultContact.phone_number, 'phone')"
														>{{ td.defaultContact.phone_number }}</span
													>
													<em v-else class="text-muted">mdi-cellphone</em>
												</div>
												<div v-if="td.defaultContact && td.defaultContact.did_number">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-phone</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.defaultContact && td.defaultContact.did_number"
														v-on:click.prevent.stop="copyNumber(td.defaultContact.did_number, 'phone')"
														>{{ td.defaultContact.did_number }}</span
													>
													<em v-else class="text-muted">no did </em>
												</div>
												<!-- <div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-web</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.website"
														v-on:click.prevent.stop="copyNumber(td.website)"
														>{{ td.website }}</span
													>
													<em v-else class="text-muted">no website </em>
												</div> -->
												<template v-if="td.contactPersonCount > 1">
													<v-chip small color="blue white--text" label class="ml-2">
														<span class=""> +{{ td.contactPersonCount - 1 }} More</span>
													</v-chip>
												</template>

												<!-- <span v-if="td.contacts">{{ td.contacts[0].email }} </span> -->
											</template>
											<template v-else-if="th.key == 'paid_amount' && th.type == 'invoice'">
												<span v-if="td.paid_amount">
													<v-chip label color="green white--text" x-small v-if="td && td.paid_amount">
														<span class="fw-500" v-if="td && td.paid_amount">
															<!-- ${{ td.paid_amount }} -->
															{{ formatMoney(td.paid_amount) }}</span
														>
													</v-chip>
												</span>
												<span v-else> -- </span>
											</template>
											<template v-else-if="th.key == 'status' && th.type == 'customer'">
												<template v-if="td.status == 'Active'">
													<Chip class="text-capitalize" text="Active" color="green"></Chip>
												</template>
												<template v-else>
													<Chip class="text-capitalize" text="In Active" color="orange"></Chip>
												</template>
											</template>
											<template v-else-if="th.key == 'payable_amount' && th.type == 'invoice'">
												<span v-if="td.payable_amount">
													<v-chip
														label
														color="orange white--text"
														outlined
														text-color=""
														x-small
														v-if="td && td.payable_amount"
													>
														<span class="fw-500" v-if="td && td.payable_amount">
															<!-- ${{ td.payable_amount }} -->
															{{ formatMoney(td.payable_amount) }}</span
														>
													</v-chip>
													<!-- <v-chip label color="orange white--text" outlined text-color="" x-small v-else>
														<span class="fw-500"> $ 0.00</span>
													</v-chip> -->
													<div class="mt-1">
														<v-chip
															label
															color="green white--text"
															text-color=""
															x-small
															v-if="td && td.paid_amount"
														>
															<span class="fw-500" v-if="td && td.paid_amount">
																<!-- ${{ td.paid_amount }} -->
																{{ formatMoney(td.paid_amount) }}</span
															>
														</v-chip>
														<v-chip label color="green white--text" text-color="" x-small v-else>
															<span class="fw-500"> $ 0.00</span>
														</v-chip>
													</div>
													<div class="mt-1">
														<v-chip
															label
															color="red white--text"
															outlined
															text-color=""
															x-small
															v-if="td && pendingBalance(td) > 0"
														>
															<span class="fw-500" v-if="td && pendingBalance(td) > 0">
																{{ pendingBalance(td) }}</span
															>
														</v-chip>
														<v-chip label color="red white--text" outlined text-color="" x-small v-else>
															<span class="fw-500">{{ pendingBalance(td) }}</span>
														</v-chip>
													</div>
												</span>
												<span v-else> -- </span>
											</template>
											<template v-else-if="th.key == 'company_name' && th.type == 'lead'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-home</v-icon>
														</b></label
													>
													<span v-if="td.company_name"
														><b>{{ td.company_name }}</b></span
													>
													<em v-else class="text-muted">no name</em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-email</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.email"
														v-on:click.prevent.stop="copyNumber(td.email)"
														>{{ td.email }}</span
													>
													<em v-else class="text-muted">no company email</em>
												</div>
												<div v-if="td.phone && td.phone">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-cellphone</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.phone && td.phone"
														v-on:click.prevent.stop="copyNumber(td.phone, 'phone')"
														>{{ td.phone }}</span
													>
													<em v-else class="text-muted">no company number </em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-web</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.website"
														v-on:click.prevent.stop="copyNumber(td.website)"
														>{{ td.website }}</span
													>
													<em v-else class="text-muted">no website </em>
												</div>
											</template>
											<template v-else-if="th.key == 'company_name' && th.type == 'customer'">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-home</v-icon>
														</b></label
													>
													<span v-if="td.company_name"
														><b>{{ td.company_name }}</b></span
													>
													<em v-else class="text-muted">no name</em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-unicode</v-icon>
														</b></label
													>
													<span class="cursor-pointer" v-if="td.company_uen">{{ td.company_uen }}</span>
													<em v-else class="text-muted">no company uen</em>
												</div>
												<div v-if="td.phone && td.phone">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-cellphone</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.phone && td.phone"
														v-on:click.prevent.stop="copyNumber(td.phone, 'phone')"
														>{{ td.phone }}</span
													>
													<em v-else class="text-muted">no phone number </em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-web</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.website"
														v-on:click.prevent.stop="copyNumber(td.website)"
														>{{ td.website }}</span
													>
													<em v-else class="text-muted">no website </em>
												</div>
											</template>
											<template v-else-if="th.key == 'email'">
												<div class="text-truncate" style="max-width: 16rem">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-account</v-icon>
														</b></label
													>
													<span v-if="td.defaultContact && td.defaultContact.name"
														><b>{{ td.defaultContact.name }}</b></span
													>
													<em v-else class="text-muted">no name</em>
												</div>
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-email</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.defaultContact && td.defaultContact.email_address"
														v-on:click.prevent.stop="copyNumber(td.defaultContact.email_address)"
														>{{ td.defaultContact.email_address }}</span
													>
													<em v-else class="text-muted">no email</em>
												</div>
												<!-- <div v-if="td.phone && td.phone">
													<label class="font-size-14 mb-1 mr-1"><b>
															<v-icon size="18">mdi-phone-classic</v-icon>
														</b></label>
													<span class="cursor-pointer" v-if="td.phone && td.phone"
														v-on:click.prevent.stop="copyNumber(td.phone, 'phone')">{{ td.phone
														}}</span>
													<em v-else class="text-muted">no company number </em>
												</div> -->
												<div v-if="td.defaultContact && td.defaultContact.phone_number">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-cellphone</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.defaultContact && td.defaultContact.phone_number"
														v-on:click.prevent.stop="copyNumber(td.defaultContact.phone_number, 'phone')"
														>{{ td.defaultContact.phone_number }}</span
													>
													<em v-else class="text-muted">no phone / mobile number</em>
												</div>
												<div v-if="td.defaultContact && td.defaultContact.did_number">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-phone</v-icon>
														</b></label
													>
													<span
														class="cursor-pointer"
														v-if="td.defaultContact && td.defaultContact.did_number"
														v-on:click.prevent.stop="copyNumber(td.defaultContact.did_number, 'phone')"
														>{{ td.defaultContact.did_number }}</span
													>
													<em v-else class="text-muted">no did </em>
												</div>

												<template v-if="td.contactPersonCount > 1">
													<v-chip small color="blue white--text" label class="ml-2">
														<span class=""> +{{ td.contactPersonCount - 1 }} More</span>
													</v-chip>
												</template>
												<!-- <span v-if="td.contacts">{{ td.contacts[0].email }} </span> -->
											</template>
											<template v-else-if="th.key == 'customer_phone'">
												<span v-if="td.contacts">{{ td.contacts[0].phone }}</span>
											</template>
											<template v-else-if="th.key == 'address'">
												<div class="text-truncate" :style="`width:${th.width}px`">
													<em
														class="text-muted mb-2"
														v-if="
															!td.address_line_1 &&
															!td.address_line_2 &&
															!td.unit_number &&
															!td.country &&
															!td.postal_code
														"
														>no address</em
													>
													<span>
														<template v-if="td.address_line_1">
															{{ td.address_line_1 + "," }}<template v-if="td.address_line_2"><br /></template>
														</template>

														<template v-if="td.address_line_2">{{ td.address_line_2 + "," }} </template>
														<template v-if="td.unit_number">
															<span>{{ td.unit_number }},</span>
														</template>
														<br />
														<template v-if="td.country">
															<span style="text-transform: capitalize">{{ td.country + "," }}</span>
														</template>
														<template v-if="td.postal_code">{{ td.postal_code }}</template>
													</span>
												</div>
											</template>
											<!-- 	<template v-else-if="th.key == 'source'">
												<span v-if="td.source">{{ td.source }}</span>
												<em v-else class="text-muted">no source</em>
											</template> -->
											<template v-else-if="th.key == 'last_contacted'">
												<!-- <span v-if="td.contacted_today == 1">{{ td.added_at }}</span>
												<em v-else class="text-muted">No Last contacted</em> -->
												<!-- <div>
													<template v-if="td.user_name">
														<Chip :text="td.user_name" color="blue" outlined text-color=""></Chip>
													</template>
												</div> -->

												<div @click.stop class="d-flex justify-space-between align-center">
													<v-menu
														offset-y
														min-width="100px"
														style="padding: 0px 4px"
														content-class="rounded-lg"
														:disabled="currentUser.role != 1 && currentUser.role != 4"
														nudgeLeft="155"
														:menu-props="{ offsetY: true }"
													>
														<template #activator="{ on, attrs }">
															<div
																v-bind="attrs"
																v-on="on"
																class="d-flex justify-space-between align-center"
																style="
																	padding: 0px 6px;
																	background-color: #f7faff;
																	width: 120px;
																	border-radius: 9px;
																	border: 1px solid #2196f3;
																"
															>
																<span class="text-capitalize font-level-1 blue--text"
																	>{{ td.user_name.replace("-", " ") }}
																</span>
																<v-icon style="font-size: 22px" color="blue" small>mdi-menu-down</v-icon>
																<!-- <inline-svg fill="#808080" v-bind="attrs" v-on="on"
																			:src="$assetURL('media/svg/expand-more.svg')" /> -->
															</div>
														</template>

														<v-list outlined max-height="300px" elevation>
															<v-list-item
																@change="updateOpportunity(item.id, td.id, 'assign', bkey, item.display_name)"
																class="list-item white cursor-pointer p-1"
																v-for="(item, index) in userList"
																:key="index"
															>
																<div class="d-flex py-1 px-1">
																	<v-avatar class="mr-2" size="36">
																		<v-img v-if="item.profile_img" :src="item.profile_img" />
																		<v-img v-else src="/media/users/blank.png"></v-img>
																	</v-avatar>
																	<div>
																		<div class="fw-600 text-uppercase">{{ item.display_name }}</div>
																		<div class="text-muted fw-500">{{ item.email }}</div>
																	</div>
																</div>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>

												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<span v-on="on" v-bind="attrs">
															<i class="fa-solid fa-clock-rotate-left"></i>
															<!-- <pre>{{ data }}</pre> -->
															<!-- 	<v-icon class="mdi-16px">mdi-clock-outline</v-icon> -->
															<template v-if="td && td.added_at && td.contacted_today == 1">
																{{ td.added_at }}
															</template>

															<em v-else class="text-muted"> no last contacted </em>
														</span>
													</template>
													<span v-if="td && td.added_at && td.contacted_today == 1">{{
														formatDateTime(td.added_at_format)
													}}</span>
												</v-tooltip>
												<div>
													<template v-if="td.is_public == 1">
														<Chip text="Public" color="pink" outlined text-color=""></Chip>
													</template>
												</div>
											</template>

											<template v-else-if="th.key == 'location' && th.type == 'meeting'">
												<template v-if="td.location">
													<div class="d-flex">
														<label class="font-size-14 mb-1 mr-1"
															><b>
																<v-icon size="18">mdi-map-marker</v-icon>
															</b></label
														>
														<div
															class="cursor-pointer text-truncate"
															:style="{ width: th.width + 'px' }"
															v-if="td.location && td.location"
															@click.stop="copyNumber(td.location)"
														>
															{{ td.location }}
														</div>
													</div>
												</template>
												<template v-else>
													<em class="text-muted"> no location</em>
												</template>
											</template>
											<template v-else-if="th.key == 'updated_at' && th.type == 'meeting'">
												<Chip
													v-if="td.updated_at"
													class="text-capitalize"
													:text="td.updated_at"
													color="green"
													text-color=""
													outlined
												></Chip>
												<span v-else>-</span>
											</template>

											<template v-else-if="th.key == 'remark' && th.type == 'lead'">
												<div>
													<!-- style="max-width: 120px !important" -->
													<span
														v-if="td.notes && td.notes[0] && td.notes[0].notes_remark"
														class="custom-line-dot"
														v-html="td.notes[0].notes_remark"
													></span>
													<span class="custom-line-dot" v-else-if="td.notes[0]?.attachment_name">
														{{ td.notes[0]?.attachment_name }}
													</span>
													<span v-else class="custom-line-dot"
														><em v-if="!td.notes[0].notes_remark" class="text-muted">no notes</em>
													</span>

													<v-icon
														style="font-size: 18px; margin-top: -12px"
														:color="
															!td.notes[0].notes_remark && !td.notes[0]?.attachment_name ? '#c5bfbf' : 'green'
														"
														small
														v-if="td"
														@click.stop.prevent="openRemark(td, 'lead', th.key)"
														>mdi-chat
													</v-icon>
												</div>
											</template>
											<template v-else-if="th.key == 'remark' && th.type == 'customer'">
												<span
													v-if="td.notes && td.notes[0] && td.notes[0].notes_remark"
													class="custom-line-dot"
													v-html="td.notes[0].notes_remark"
												></span>
												<span v-else-if="td.notes[0]?.attachment_name">
													{{ td.notes[0]?.attachment_name }}
												</span>
												<span v-else class="custom-line-dot"
													><em v-if="!td.notes[0].notes_remark" class="text-muted">no notes</em></span
												>
												<v-icon
													style="font-size: 18px; margin-top: -12px"
													:color="
														!td.notes[0].notes_remark && !td.notes[0]?.attachment_name ? '#c5bfbf' : 'green'
													"
													small
													v-if="td"
													v-on:click.stop.prevent="openRemark(td, 'customer', th.key)"
													>mdi-chat
												</v-icon>
												<em v-else class="text-muted">no notes</em>
											</template>
											<!-- 	<template v-else-if="th.key == 'display_name' && th.type == 'user'">
												
												<span v-if="td.notes[0].notes_remark" class="custom-line-dot">{{ td.display_name }}</span>
												<em v-else class="text-muted">no display name</em>
											</template> -->
											<template v-else-if="th.key == 'barcode' && th.type == 'invoice'">
												<!-- 	<div class="mb-2">
													<label class="font-size-14 mb-1 mr-4"><b> Invoice # : </b></label>
													<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
													<span v-else>--</span>
												</div>
												<label class="font-size-14 mb-1 mr-4"><b> Project # : </b></label>
												<Chip :text="td.project_barcode" color="orange" v-if="td.project_barcode"> </Chip> -->
												<div class="d-flex">
													<div class="mb-2">
														<label class="font-size-14 mb-1 mr-4"><b> Quick Book Invoice # : </b></label>
														<Chip :text="td.barcode" color="cyan" v-if="td.barcode"></Chip>
														<em class="text-muted" v-else>no invoice</em>
														<div class="d-flex justify-space-between mt-1">
															<label class="font-size-14 mb-1 mr-5"><b> Project # : </b></label>
															<Chip :text="td.project_barcode" color="orange" v-if="td.project_barcode"></Chip>
															<em class="text-muted" v-else>no project</em>
														</div>
													</div>

													<div v-if="td.project_renewed > 0" class="d-flex align-center justify-center ml-2">
														<!-- <span v-if="td.project_status == 1 && td.project_renewed == 0" class="mb-2">
															<v-avatar color="red" size="35" class="mr-2">
																<span class="white--text fw-600" style="font-size: 18px"> R </span>
															</v-avatar>
														</span> -->
														<v-avatar color="red" size="27">
															<span class="white--text fw-700"> R {{ td.project_renewed }}</span>
														</v-avatar>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'project_entity_type'">
												<div>
													<!-- <label class="font-size-14 mb-1 mr-5"><b> Entity : </b></label> -->
													<div style="max-width: 11rem" class="fw-600 pa-1 pl-2 rounded-sm mt-1">
														<img
															style="max-width: 25px"
															:src="
																td.project_entity_type == 'bthrust'
																	? $assetURL('media/project-logos/bthrust-icon.png')
																	: $assetURL('media/project-logos/genic.png')
															"
															alt="Entity"
														/>
														{{ mod_string(td.project_entity_type, "-", " ") }}
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'status'">
												<Chip class="text-capitalize" :text="changeStatus(td.status)" color="orange"></Chip>
											</template>

											<template v-else-if="th.key == 'activated'">
												<template v-if="td.activated == 'Active'">
													<Chip class="text-capitalize" text="Active" color="green"></Chip>
												</template>
												<template v-else>
													<Chip class="text-capitalize" text="In Active" color="orange"></Chip>
												</template>
											</template>

											<template v-else-if="th.key == 'type' && th.type == 'holiday'">
												<Chip class="text-capitalize" v-if="td.type == 1" text="Full Day" color="orange"></Chip>
												<Chip class="text-capitalize" v-else text="Half Day" color="orange"></Chip>
											</template>
											<template v-else-if="th.key == 'attachment' && th.type == 'leave'">
												<template v-for="(attachmentInfo, index) in td.attachment">
													<span class="mb-1" :key="index" v-if="index < 3">
														<template v-if="attachmentInfo.att_url">
															<div v-if="false" class="d-flex align-items-center">
																<div>
																	<v-btn
																		small
																		icon
																		depressed
																		color="blue darken-4"
																		class="mr-1"
																		v-on:click.prevent.stop="downloadAttachment(attachmentInfo.att_url)"
																	>
																		<v-icon small>mdi-download</v-icon>
																	</v-btn>
																</div>
																<div class="ml-2">
																	<div>
																		{{ attachmentInfo.att_name }}
																	</div>
																</div>
															</div>
															<!-- {{ attachmentInfo.att_name.slice(-4) }} -->
															<v-tooltip bottom>
																<template #activator="{ on, attrs }">
																	<v-btn
																		small
																		icon
																		v-on="on"
																		v-bind="attrs"
																		depressed
																		color="red lighten-1"
																		class="mr-1"
																		v-on:click.prevent.stop="downloadAttachment(attachmentInfo.att_url)"
																	>
																		<v-icon
																			:color="
																				attachmentInfo.att_name.slice(-4).includes('pdf')
																					? 'red'
																					: attachmentInfo.att_name.slice(-4).includes('jpg') ||
																					  attachmentInfo.att_name.slice(-4).includes('png')
																					? 'blue'
																					: 'green'
																			"
																		>
																			{{
																				attachmentInfo.att_name.slice(-4).includes("pdf")
																					? "mdi-file-pdf-outline"
																					: attachmentInfo.att_name.slice(-4).includes("jpg") ||
																					  attachmentInfo.att_name.slice(-4).includes("png")
																					? "mdi-file-image-outline"
																					: "mdi-file-excel-outline"
																			}}
																		</v-icon>

																		<!-- <v-icon small v-if="attachmentInfo.att_name.slice(-4).includes('xlsx')">mdi-xlsx</v-icon>
																		<v-icon small v-if="attachmentInfo.att_name.slice(-4).includes('jpg')">mdi-file-jpg-box</v-icon> -->
																	</v-btn>
																</template>
																<span>{{ attachmentInfo.att_name }}</span>
															</v-tooltip>
														</template>

														<template v-else>
															<em class="text-muted ml-2"> no attachment</em>
														</template>
														<!-- <div class="d-flex py-1 px-2">
															<span class="icon">
																<v-icon
																	class="mdi-16px mr-1 primary--text"
																	v-on:click="downloadAttachment(attachmentInfo.att_url)"
																	>mdi-download</v-icon
																>
															</span>
															<span
																v-if="attachmentInfo && attachmentInfo.att_name"
																class="ellipsis primary--text"
																style="max-width: 120px"
															>
																{{ attachmentInfo.att_name }}</span
															>&nbsp;
															<span class="size" v-if="attachmentInfo && attachmentInfo.size">
																({{ attachmentInfo.size }})</span
															>
															<v-spacer></v-spacer>
														</div> -->
													</span>
													<span v-if="index == 3" :key="index + 'more'" class="ml-2">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-avatar
																	v-on="on"
																	v-bind="attrs"
																	size="30"
																	style="border: 1px solid grey !important"
																	class="ml-n3 white"
																>
																	<span> + {{ td.attachment.length - 3 }} </span>
																</v-avatar>
															</template>
															<span>{{ td.attachment.length - 3 }} More</span>
														</v-tooltip>
													</span>
												</template>
											</template>
											<template v-else-if="th.key == 'lead_barcode' && th.type == 'task'">
												<template v-if="td.related_to != 'task'">
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Lead # : </b></label>
														<span v-if="td.lead_barcode">{{ td.lead_barcode }} </span>
														<em class="text-muted" v-else> no lead # </em>
													</div>
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Company Name : </b></label>
														<span v-if="td.related">{{ td.related }} </span>
														<em class="text-muted" v-else> no company name </em>
													</div>
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Person Incharge : </b></label>
														<span v-if="td.lead_customer_name">{{ td.lead_customer_name }}</span>
														<em v-else class="text-muted">no person incharge</em>
													</div>
													<div>
														<label class="font-size-14 mb-1 mr-1"><b> Lead Date : </b></label>
														<span v-if="td.lead_date">{{ formatDate(td.lead_date) }}</span>
														<em v-else class="text-muted">no lead date</em>
													</div>
												</template>
												<template v-else>
													<em class="text-muted"> no lead detail</em>
												</template>
											</template>

											<template v-else-if="th.key == 'working' && th.type == 'holiday'">
												<Chip class="text-capitalize" v-if="td.working == 1" text="Working" color="cyan"></Chip>
												<Chip class="text-capitalize" v-else text="Non Working" color="cyan"></Chip>
											</template>
											<!-- <template v-else-if="th.key == 'reminder' && td.reminder && td.reminder.type == 1">
												<div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-calendar</v-icon>
														</b></label
													>
													<span v-if="td.reminder && td.reminder.start_date"
														>{{ formatDate(td.reminder.start_date) }}
													</span>
													<em v-else class="text-muted">no date</em>
												</div>
											</template> -->

											<template v-else-if="th.key == 'reminder' && td.reminder">
												<div class="cursor-pointer mb-1">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-calendar</v-icon>
														</b></label
													>
													<span
														:class="getColorClass(td.reminder.start_date)"
														style="font-weight: 500"
														v-if="
															_getValue(td, 'reminder.start_date') && _getValue(td, 'upcomingReminderCount') > 0
														"
														>{{ formatDate(td.reminder.start_date) }}
														<v-icon small color="blue">mdi-pencil</v-icon>
													</span>

													<em v-else class="text-muted">no reminder date</em>
												</div>
												<!-- upcomingReminderCount | reminderCount -->
												<template v-if="td.upcomingReminderCount > 1">
													<v-chip
														small
														color="blue white--text"
														label
														class="ml-2"
														v-on:click.prevent.stop="listReminder(td)"
													>
														<span class=""> +{{ td.upcomingReminderCount - 1 }} More</span>
													</v-chip>
												</template>
												<!-- <div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-calendar</v-icon>
														</b></label
													>
													<span v-if="td.reminder && td.reminder.end_date">{{
														formatDate(td.reminder.end_date)
													}}</span>
													<em v-else class="text-muted">no end date</em>
												</div> -->
											</template>

											<template v-else-if="th.key == 'start_from'">
												<label class="font-size-14 mb-1 mr-1"
													><b>
														<v-icon size="18">mdi-calendar</v-icon>
													</b></label
												>
												<span v-if="td.start_from"
													>{{ formatDate(td.start_from) }} {{ formatTime22(td.start_time) }}
													<!-- To {{ formatDate(td.end_to) }} --></span
												>
											</template>

											<template v-else-if="th.key == 'end_date' && th.type == 'leave'">
												<span v-if="td.end_date">{{ formatDate(td.end_date) }}</span>
											</template>

											<template v-else-if="th.key == 'added_at'">
												<div>
													<Chip
														class="text-capitalize"
														:text="td.added_at"
														color="green"
														text-color=""
														outlined
													></Chip>
												</div>

												<v-chip
													tag="div"
													outlined
													color="primary"
													class="ps-0 mt-1"
													v-if="th.type == 'customer'"
												>
													<v-avatar>
														<v-img v-if="td.addedUser.profile_img" :src="td.addedUser.profile_img" />
														<v-img
															v-else
															src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
														/>
													</v-avatar>
													<span class="fw-500 ms-1 text-capitalize">{{ td.addedUser.display }}</span>
												</v-chip>

												<div class="mt-1">
													<v-chip small v-if="td.user_name && th.type != 'lead'" outlined class="pl-1">
														<v-avatar left>
															<img v-if="td.profile_img" :src="td.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														{{ td.user_name }}
													</v-chip>

													<v-chip small v-if="td?.added_by && th.type == 'lead'" outlined class="pl-1">
														<v-avatar left>
															<img v-if="td?.added_by?.profile_img" :src="td?.added_by?.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														{{ td?.added_by?.display_name }}
													</v-chip>
												</div>
											</template>

											<template v-else-if="th.key == 'modified_at'">
												<TableDateTime
													v-if="td.modified_at && false"
													:human-format="td.modified_at"
													:system-format="td.updated_at"
												></TableDateTime>
												<Chip
													v-if="td.modified_at"
													class="text-capitalize"
													:text="td.modified_at"
													color="green"
													text-color=""
													outlined
												></Chip>
												<span v-else>-</span>
											</template>
											<template v-else-if="th.key == 'phone' && th.type == 'meeting'">
												<label class="font-size-14 mb-1 mr-1"
													><b>
														<v-icon size="18">mdi-cellphone</v-icon>
													</b></label
												>
												<span
													class="cursor-pointer"
													v-if="td.customers_phone && td.customers_phone"
													v-on:click.prevent.stop="copyNumber(td.customers_phone, 'customers_phone')"
													>{{ td.customers_phone }}</span
												>
												<span
													class="cursor-pointer"
													v-else-if="td.lead_company_phone && td.lead_company_phone"
													v-on:click.prevent.stop="copyNumber(td.lead_company_phone, 'lead_company_phone')"
													>{{ td.lead_company_phone }}</span
												>
												<em v-else class="text-muted">no phone </em>
											</template>

											<template v-else-if="th.key == 'meeting_type'">
												<template v-if="td && td.meeting_type == 'zoom'">
													<div>
														<v-chip
															class="ml-2 text-capitalize mb-1"
															color="blue white--text"
															outlined
															x-small
															label
															v-if="td && td.meeting_type"
															>{{ td.meeting_type }}
														</v-chip>
													</div>
													<!-- <div class="ml-2">
														<a :href="td.host_url" target="_blank" rel="noopener noreferrer">Meeting URL</a>
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<span v-on="on" v-bind="attrs">
																	<i class="fa-solid fa-clock-rotate-left"></i>
																	<v-icon v-on:click.prevent.stop="copyNumber(td.host_url)" class="mdi-16px ml-1"
																		>mdi-content-copy</v-icon
																	>
																</span>
															</template>
															<span>Copy URL</span>
														</v-tooltip>
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<span v-on="on" v-bind="attrs">
																	<i class="fa-solid fa-clock-rotate-left"></i>
																	<v-icon v-on:click.prevent.stop="sendMailData(td)" class="mdi-16px ml-1"
																		>mdi-share-variant</v-icon
																	>
																</span>
															</template>
															<span>Share</span>
														</v-tooltip>
													</div> -->
												</template>
												<template v-if="td && td.meeting_type == 'bthrust'">
													<v-chip
														class="ml-2 text-capitalize"
														color="red white--text"
														x-small
														outlined
														label
														v-if="td && td.meeting_type"
														>F2F Bthrust
													</v-chip>
												</template>
												<template v-if="td && td.meeting_type == 'client'">
													<v-chip
														class="ml-2 text-capitalize"
														color="green white--text"
														outlined
														x-small
														label
														v-if="td && td.meeting_type"
														>F2F Client
													</v-chip>
												</template>
												<template v-if="td && td.meeting_type == 'other'">
													<v-chip
														class="ml-2 text-capitalize"
														color="orange white--text"
														x-small
														outlined
														label
														v-if="td && td.meeting_type"
														>Zoom
													</v-chip>
												</template>
												<template v-if="td && td.meeting_type == 'other2'">
													<v-chip
														class="ml-2 text-capitalize"
														color="orange white--text"
														x-small
														outlined
														label
														v-if="td && td.meeting_type"
														>Other
													</v-chip>
												</template>
											</template>

											<template v-else-if="th.key == 'priority'">
												<template v-if="td && td.priority == 'low'">
													<div>
														<v-chip
															class="ml-2 text-capitalize mb-1"
															color="cyan white--text"
															outlined
															x-small
															label
															v-if="td && td.priority"
															>{{ td.priority }}
														</v-chip>
													</div>
												</template>
												<template v-if="td && td.priority == 'high'">
													<v-chip
														class="ml-2 text-capitalize"
														color="orange white--text"
														x-small
														outlined
														label
														v-if="td && td.priority"
														>{{ td.priority }}
													</v-chip>
												</template>
												<template v-if="td && td.priority == 'medium'">
													<v-chip
														class="ml-2 text-capitalize"
														color="green white--text"
														outlined
														x-small
														label
														v-if="td && td.priority"
														>{{ td.priority }}
													</v-chip>
												</template>
												<template v-if="td && td.priority == 'urgent'">
													<v-chip
														class="ml-2 text-capitalize"
														color="blue white--text"
														x-small
														outlined
														label
														v-if="td && td.priority"
														>{{ td.priority }}
													</v-chip>
												</template>
											</template>

											<template v-else-if="th.key == 'title' && th.type == 'meeting'">
												<div :style="{ minWidth: `${300}px` }">
													<!-- <div class="mb-1">
														<span class="fw-600">#</span>&nbsp;
														<v-chip label color="cyan" dark x-small class="px-1">
															<span class="fs-10">{{ td.barcode }}</span>
														</v-chip>
													</div> -->
													<v-tooltip top content-class="custom-top-tooltip">
														<template #activator="{ on, attrs }">
															<div
																v-on="on"
																v-bind="attrs"
																:style="{ maxWidth: '290px' }"
																class="text-truncate fw-600 fs-15 dark-text text-capitalize"
															>
																{{ td[th.key] }}
															</div>
														</template>
														<span class="text-capitalize">{{ td[th.key] }}</span>
													</v-tooltip>
												</div>
											</template>

											<template v-else-if="th.key == 'meeting_attendies'">
												<div v-if="td.attendies" class="d-flex align-items-center mt-1">
													<template v-if="td.attendies && td.attendies.length">
														<template v-for="(row, index) in td.attendies">
															<span v-if="index < 2" :key="index + 'avatar'">
																<v-tooltip top content-class="custom-top-tooltip">
																	<template v-slot:activator="{ on, attrs }">
																		<v-avatar
																			v-on="on"
																			v-bind="attrs"
																			size="30"
																			class="white"
																			:class="{
																				'ml-n3': index,
																			}"
																			style="border: 1px solid grey !important"
																		>
																			<v-img v-if="row.image" :src="row.image"></v-img>
																			<span v-else class="text-uppercase">{{
																				row.display_name && row.display_name.length > 1
																					? row.display_name.slice(0, 2)
																					: ""
																			}}</span>
																		</v-avatar>
																	</template>
																	<span>{{ row.display_name }}</span>
																</v-tooltip>
															</span>
															<span v-if="index == 2" :key="index + 'more'">
																<v-tooltip top content-class="custom-top-tooltip">
																	<template v-slot:activator="{ on, attrs }">
																		<v-avatar
																			v-on="on"
																			v-bind="attrs"
																			size="30"
																			style="border: 1px solid grey !important"
																			class="ml-n3 white"
																		>
																			<span> + {{ td.attendies.length - 2 }} </span>
																		</v-avatar>
																	</template>
																	<span>{{ td.attendies.length - 2 }} More</span>
																</v-tooltip>
															</span>
														</template>
													</template>
													<em v-else>no attendies</em>
												</div>
												<span v-else>-</span>
											</template>

											<template v-else-if="th.key == 'task_attendies'">
												<div v-if="td.attendies" class="d-flex align-items-center mt-1">
													<span v-for="(row, index) in td.attendies" :key="index" class="cursor-initial">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-avatar
																	width="30px"
																	min-width="30px"
																	height="30px"
																	class="pa-0 lighten-3 white--text mr-1"
																	:class="td.status_color"
																	v-bind="attrs"
																	v-on="on"
																	left
																>
																	<img v-if="row.image" :src="row.image" :alt="row.display_name" />
																	<img
																		v-else
																		src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																	/>
																</v-avatar>
																<span class="fw-500"> {{ row.display_name }}</span>
															</template>
															<span>{{ row.display_name }}</span>
														</v-tooltip>
													</span>
												</div>
												<span v-else>-</span>
											</template>

											<template v-else>
												<div style="max-width: 250px" class="text-truncate">
													<ShowValue :object="td" :object-key="th.key" :label="th.name"> </ShowValue>
												</div>
											</template>
										</td>
									</template>
								</tr>
							</v-hover>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<v-row>
				<v-col md="6" class="my-auto">
					<div class="d-flex m-0">
						<div class="m-0">{{ showingString }}</div>
						<div v-if="selected.length > 0" class="m-0 d-flex ml-3 justify-content-end">
							(<em class="text-muted">
								Selected <b>{{ selected.length }}</b> {{ selected.length > 1 ? type + "s" : type }} </em
							>)
						</div>
					</div>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="totalPage"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row>
		</template>

		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete {{ type }}</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">{{ type }} </span>
							<span class="red--text font-bolder lighten-1">#{{ deleteText }}</span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<!-- <v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col> -->
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteButton"
					:disabled="deleteButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteRow"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteButton" v-on:click="deleteClear"> No, Close </v-btn>
			</template>
		</Dialog>
		<template>
			<FullDialog dense :dialog="remarkDialog">
				<template #title
					>Lead {{ leadBarcode }} Notes
					<v-spacer></v-spacer>
					<v-btn
						depressed
						tile
						class="text-white"
						color="red darken-4"
						:disabled="pageLoading"
						icon
						@click="closeDialog('remark', 'lead')"
					>
						<v-icon>mdi-close-circle</v-icon>
					</v-btn>
				</template>
				<template #body>
					<AllNotesTab
						:relatedId="lead_id"
						:relatedType="'lead-notes'"
						:siggest-list="notesUserArr"
						create-url="lead-note"
						get-url="lead-note"
					>
					</AllNotesTab>
				</template>
				<!-- <template v-slot:action>
					<v-btn
						depressed
						tile
						class="text-white"
						color="red darken-4"
						:disabled="pageLoading"
						v-on:click="closeDialog('remark', 'lead')"
					>
						Close
					</v-btn>
				</template> -->
			</FullDialog>
			<FullDialog dense :dialog="remarkCustomerDialog" content-class="testdata">
				<template #title
					>Customer {{ leadBarcode }} Notes
					<v-spacer></v-spacer>
					<v-icon color="red darken-4" @click="closeDialog('remark', 'customer')"
						>mdi-close-circle</v-icon
					>
				</template>
				<template #body>
					<AllNotesTab
						:relatedId="lead_id"
						:relatedType="'customer-notes'"
						:siggest-list="notesUserArr"
						create-url="customer-note"
						get-url="customer-note"
					>
					</AllNotesTab>
				</template>
				<!-- <template #action>
					<v-btn
						class="text-white"
						color="red darken-4"
						depressed
						tile
						:disabled="pageLoading"
						v-on:click="closeDialog('remark', 'customer')"
					>
						Close
					</v-btn>
				</template> -->
			</FullDialog>

			<Dialog :dialog="serviceDialog" :dialog-width="400">
				<template v-slot:body>
					<div class="text-center">
						<!-- <img class="mb-4" :src="pico_barcode_imgae" :alt="pico_barcode_text" width="334px" />-->
						<h3 class="primary--text text-capitalize">
							{{ contractBarcode }}
							<!-- <span style="color:black;">({{contractBarcode}})	</span> -->
						</h3>

						<span v-for="(row, index) in contractServices" :key="index" class="cursor-initial">
							<template v-if="row == 'seo'">
								<Chip text="SEO" color="blue" outlined text-color="" class="mr-1"> </Chip>
							</template>
							<template v-if="row == 'hosting'">
								<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
							</template>
							<template v-if="row == 'ssl'">
								<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
							</template>
							<template v-if="row == 'web'">
								<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
							</template>
							<template v-if="row == 'domain'">
								<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
							</template>
							<template v-if="row == 'maintenance'">
								<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
							</template>
						</span>
					</div>
				</template>

				<template v-slot:action>
					<v-btn depressed tile v-on:click="serviceDialog = false">Close </v-btn>
				</template>
			</Dialog>
			<MeetingApprovalDialog
				v-if="approvalDialog"
				:dialog="approvalDialog"
				:action-type="approvalActionType"
				:claim-id="meetingData.id"
				:claim-data="meetingData"
				@close="approvalDialog = false"
				@success="meetingSuccess()"
			/>
		</template>
	</v-sheet>
</template>
<style scoped>
.v-dialog__content.v-dialog__content--active {
	z-index: 99 !important;
}

/* i.v-icon.notranslate.mdi.mdi-checkbox-marked.theme--light.red--text {
    font-size: 30px;
}
i.v-icon.notranslate.mdi.mdi-checkbox-marked.theme--light.blue--text {
    font-size: 30px;
} */
</style>
<script>
import { toSafeInteger, isArray, replace, cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import MomentJS from "moment-timezone";
import {
	SET_SELECTED_ROWS,
	SET_CURRENT_PAGE,
	SORT_TBODY,
	SET_THEAD,
	UPDATE_TABLE,
} from "@/core/services/store/listing.module";
import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import FullDialog from "@/view/components/FullDialog";
import TableDateTime from "@/view/components/TableDateTime";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import ImageTemplate from "@/view/components/Image";
import { /* GET, */ PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import objectPath from "object-path";
import MeetingApprovalDialog from "@/view/module/meeting/MeetingApprovalDialog.vue";
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "table-template",
	mixins: [ListingMixin],
	props: {
		type: {
			type: String,
			default: null,
		},
		deleteEndpoint: {
			type: String,
			default: null,
		},
		detailRoute: {
			type: String,
			default: null,
		},
		updateRoute: {
			type: String,
			default: null,
		},
		deleteNote: {
			type: String,
			default: null,
		},
		dialogUpdate: {
			type: Boolean,
			default: false,
		},
		internal: {
			type: Boolean,
			default: false,
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
		isDrawerDetail: {
			type: Boolean,
			default: false,
		},
		isDialogDetail: {
			type: Boolean,
			default: false,
		},
		statusFilterData: {
			type: Array,
		},
	},
	data() {
		return {
			checkbox: false,
			indeterminate: false,
			selectedRows: [],
			meetingData: {},
			contractBarcode: null,
			deleteDialog: false,
			opportunityList: [],
			statusList: [],
			approvalDialog: false,
			meetingStatusList: [
				{
					id: 2,
					text: "Upcoming",
					value: "Upcoming",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "purple",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 3,
					text: "Postponed",
					value: "Postponed",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "orange",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 4,
					text: "Completed",
					value: "Completed",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 49,
				},
				{
					id: 5,
					text: "Cancelled",
					value: "Cancelled",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 11,
				},
			],
			sourceList: [],
			productList: [],
			notesUserArr: [],
			userList: [],
			contractTitle: null,
			contractServices: null,
			deleteButton: false,
			openUploadModel: false,
			probisionTime: 0,
			viewType: false,
			serviceDialog: false,
			deleteID: null,
			remarkdata: {
				lead_remark: null,
			},
			deleteURL: null,
			deleteText: null,
			leadBarcode: null,
			default_remakr: null,
			default_date: null,
			remarkDialog: false,
			remarkCustomerDialog: false,
			lead_id: 0,
			remarkMsg: [],
			page: 1,
			reminderSortType: "asc",
			MeeStatusNdColor: {
				Upcoming: "grey",
				Postponed: "blue",
				Completed: "green",
				Cancelled: "red",
			},
			// settings_endpoint: 'lead-setting'
		};
	},
	watch: {
		currentPage(param) {
			this.page = param;
		},
		selected(param) {
			if (param.length < 1) {
				this.checkbox = false;
				this.indeterminate = false;
				this.selectedRows = [];
			}
		},
		$route: {
			handler() {
				this.viewType = this.$route.query.viewType;
			},
		},
		moduleSettings: {
			immediate: true,
			handler(settings) {
				if (settings && Object.keys(settings).length) {
					if (this.type == "lead") {
						this.opportunityList = [
							{
								text: "No Opportunity",
								value: null,
							},
							...settings.opportunity,
						];
						/* 	_this.statusList = settings.status; */
						let statusData = cloneDeep(settings.status);
						statusData.shift(1);
						this.statusList = statusData;
						this.sourceList = settings.source;
						this.productList = settings.product_type;
						if (Array.isArray(settings.users) && settings.users.length) {
							this.userList = settings.users.filter((user) => user.users_type == "sales");
						}
					}
				}
			},
		},
	},
	methods: {
		meetingSuccess() {
			this.$emit("reload:content", true);
		},
		// refreshMeeting(){
		// 	MeetingEventBus.$on("refresh", () => {
		// 	this.getListing();
		// });

		// },
		updateMeeting(action, td) {
			this.meetingData = td;
			this.meetingData.id = td.id;

			if (action == "Postponed") {
				this.newaction = "mark_as_postponed";
			} else if (action == "Cancelled") {
				this.newaction = "mark_as_cancelled";
			} else if (action == "Completed") {
				this.newaction = "mark_as_completed";
			} else if (action == "Upcoming") {
				this.newaction = "mark_as_postponed";
			}

			// this.pageLoading = true;

			this.openDialog(this.newaction);
		},
		openDialog(action = "") {
			if (!action) return;

			this.approvalDialog = true;
			this.approvalActionType = action;
		},

		mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		},
		headClick(th) {
			let headerClone = cloneDeep(this.thead);
			let indexOfKeyFoundInHeader = headerClone.findIndex((_th) => _th.key == th.key);
			if (indexOfKeyFoundInHeader > -1) {
				headerClone[indexOfKeyFoundInHeader].sort = "ASC";
				headerClone[indexOfKeyFoundInHeader].sortable = !headerClone[indexOfKeyFoundInHeader].sortable;
			}

			this.$store.commit(SET_THEAD, headerClone);
			this.$store
				.dispatch(UPDATE_TABLE, {
					type: headerClone[indexOfKeyFoundInHeader].type,
					params: new Object({
						tables: headerClone,
					}),
				})
				.catch((error) => {
					console.error({ error });
				}); /* .finally(() => {
				}); */
		},
		sortTableAccColumn(sortType, thead) {
			this.sort_column = thead.key;
			console.log(thead.key);

			const { name, query, params } = this.$route;
			// alert(thead.key)

			const SORT_DATA = {
				sortType,
				thead,
				sort_column: this.sort_column,
				endpoint: this.type,
				query: query,
			};
			this.$emit("toggleLoading", true);
			this.$store
				.dispatch(SORT_TBODY, SORT_DATA)
				.catch((err) => {
					console.log({ err });
				})
				.finally(() => {
					this.$emit("toggleLoading", false);
					this.$router.push({
						name,
						query: { ...query, reminder_sort: sortType, sort_column: thead.key },
						params,
					});
				});
			this.$emit("toggleLoading", false);
		},
		leadStatus(status) {
			let found = this.statusList.find((v) => v.value == status);
			if (found) {
				return found?.text;
			} else {
				return null;
			}
		},
		meetingStatus(status) {
			let found = this.meetingStatusList.find((v) => v.value == status);
			if (found) {
				return found?.text;
			} else {
				return null;
			}
		},
		leadStatusColor(status) {
			switch (status) {
				case "Not Contacted":
					return { border: "grey", text: "grey" };
				case "Follow UP":
					return { border: "orange", text: "orange" };
				case "Meeting":
					return { border: "#2196f3", text: "blue" };
				case "Quotation":
					return { border: "#e91e63", text: "pink" };
				case "Rejected":
					return { border: "red", text: "red" };
				case "Accepted":
					return { border: "green", text: "green" };
				case "50-50":
					return { border: "#2196f3", text: "blue" };
				case "high-chance":
					return { border: "green", text: "green" };
				case "low-chance":
					return { border: "grey", text: "grey" };
				case "none":
					return { border: "grey", text: "grey" };
				case null:
					return { border: "grey", text: "grey" };
			}
		},
		meetingStatusColor(status) {
			switch (status) {
				case "Upcoming":
					return { border: "purple", text: "purple" };
				case "Postponed":
					return { border: "orange", text: "orange" };
				case "Completed":
					return { border: "green", text: "green" };

				case "Cancelled":
					return { border: "red", text: "red" };
			}
		},
		_getValue(obj, key) {
			return objectPath.get(obj, key);
		},
		mark_as_read(id) {
			if (id) {
				//let unread = this.all_notification==false?1:0;
				this.$store
					.dispatch(PATCH, {
						url: `notifications/${id}`,
					})
					.then((data) => {
						console.log(data);
						//this.tbody = data;
						this.$emit("reload:content", true);
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},
		listReminder(data) {
			this.$emit("openReminderlist", data);
		},
		getColorClass(start_date = "") {
			/*if (status == 1) {
				return "red-color";
			} else if (status == 2) {
				return "blue-color";
			}*/
			var startDate = MomentJS(start_date).format("YYYY-MM-DD");
			var now = MomentJS().format("YYYY-MM-DD");
			if (startDate < now) {
				return "red-color";
			} else if (startDate >= now) {
				return "blue-color";
			}
		},
		leadPinned(id, status, type, data) {
			this.$store
				.dispatch(PATCH, {
					url: `pin-lead/${id}`,
					data: {
						value: data,
						type: type,
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Lead Pinned successfully." },
					]);
					this.$emit("reload:content", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		openPopup(data) {
			this.contractServices = data.checkbox_type;
			this.contractTitle = data.title;
			this.contractBarcode = data.barcode;
			this.contractServices = this.contractServices.split(",");
			this.serviceDialog = true;
			return false;
		},
		getDisabled(td, th) {
			if (
				(td.status == "Approved" && th.type == "leave") ||
				(td.status == "Rejected" && th.type == "leave")
			) {
				return true;
			} else if (
				/* (td.status == 'Renewed' && th.type == 'project') || (td.status == 'Cancelled' && th.type == 'project') */ th.type ==
				"project"
			) {
				return true;
			} else if (th.type == "contract") {
				//console.log(this.currentUser);
				if (
					td.status != "Draft" &&
					this.currentUser.id != td.added_by &&
					!this.currentUser.is_master &&
					this.currentUser.users_type != "accounts" &&
					this.currentUser.role != 1 &&
					this.currentUser.role != 4 &&
					this.currentUser.role != 6 &&
					this.currentUser.role != 7 &&
					this.currentUser.role != 12 &&
					this.currentUser.role != 13
				)
					return true;
				if (td.status != "Draft") return true;
			} else if ((td.status == "Completed" || td.status == "Cancelled") && th.type == "meeting") {
				return true;
			} else if (
				(td.status == "Approved" ||
					td.status == "Rejected" ||
					td.user != this.AllowAnyOforGetRoleNType()?.id) &&
				th.type == "claim"
			) {
				return true;
			} else {
				return false;
			}
			/*   return (td.status == 'Approved' && th.type == 'leave') || (td.status == 'Rejected' && th.type == 'leave') ? true : (td.status == 'Renewed' && th.type == 'project') || (td.status == 'Cancelled' && th.type == 'project') ? true :(td.status != 'Draft' && th.type == 'contract') ? true :false */
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		addDate(date) {
			let newDate = MomentJS(date, "YYYY-MM-DD").subtract(15, "days").format("YYYY-MM-DD");
			return this.formatDate(newDate);
		},
		pendingBalance(data) {
			/* Change to Floating Point Number */
			let result = data.payable_amount - data.paid_amount;
			return this.formatMoney(result);
		},
		sendMailData(data) {
			this.$emit("mailSend", data);
		},
		/* getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					_this.opportunityList = [
						{
							text: "No Opportunity",
							value: null,
						},
						...data.opportunity,
					];
						_this.statusList = data.status;
					let statusData = cloneDeep(data.status);
					statusData.shift(1);
					_this.statusList = statusData;
					_this.sourceList = data.source;
					_this.productList = data.product_type;
					_this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// _this.pageLoading = false;
				});
		}, */
		getProvision(prodate) {
			let a = MomentJS();
			let b = MomentJS(prodate);
			return b.diff(a, "days");
		},
		getDate(row) {
			if (row.actual_date) {
				let addDates = MomentJS(row.start_date, "YYYY-MM-DD").subtract(15, "days");
				let invoiceDate = MomentJS(row.actual_date, "YYYY-MM-DD");
				return invoiceDate.diff(addDates, "days");
			}
		},
		updateOpportunity(value, id, type, index, text) {
			if (type == "status") {
				this.tbody[index].status = value;
			} else if (type == "assign") {
				this.tbody[index].user_name = text;
			} else if (type == "opportunity") {
				this.tbody[index].opportunity = value;
			} else if (type == "product_type") {
				this.tbody[index].product_type = value;
			} else if (type == "source") {
				this.tbody[index].source = value;
			}
			var typeKey = type;
			this.$store
				.dispatch(PATCH, {
					url: `update-opportunity/${id}`,
					data: {
						[typeKey]: value,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! ${text} updated successfully.` },
					]);
				})
				.catch((error) => {
					this.$emit("reload:content", true), console.log({ error });
				});
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		closeDialog(value, type) {
			if (value == "reminder") {
				this.reminderDialog = false;
			} else {
				if (type == "lead") {
					this.remarkDialog = false;
				} else {
					this.remarkCustomerDialog = false;
				}
			}
		},
		getStatusText(status) {
			if (status == "In Progress") {
				return "In-Progress";
			}
			if (status == "Completed") {
				return "Completed";
			}
			if (status == "Pending") {
				return "Pending";
			}
		},
		getcategoryColor(cate) {
			if (cate == "owan") {
				return "red  white--text";
			} else {
				return "blue white--text";
			}
		},
		getStatusColor(status) {
			if (status == "In Progress") {
				return "red";
			}
			if (status == "Completed") {
				return "green";
			}
			if (status == "Pending") {
				return "orange";
			}
			return "cyan";
		},
		downloadAttachment(url) {
			window.open(url, "_blank");
		},
		openRemark(data, type) {
			this.leadBarcode = data.barcode;
			this.default_remakr = data.remark;
			this.default_date = MomentJS(data.added_at);
			this.lead_id = data.id;
			if (type == "lead") {
				this.remarkDialog = true;
				if (data && data.user && this.notesUserArr.length == 0) {
					this.notesUserArr.push({
						id: data.user,
						profile_img: data.user_profile_img ? data.user_profile_img : null,
						barcode: data.user_barcode,
						display_name: data.user_name ? data.user_name : null,
					});
				}
			} else {
				this.remarkCustomerDialog = true;
				if (data && data.attendies && this.notesUserArr.length == 0) {
					this.notesUserArr = data.attendies;
				}
			}
		},

		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.$emit("reload:content", true);
			});
		},
		routeToDetail(order, id, type, updateData, key) {
			if (type == "notification") {
				return false;
			}
			if (order < 2 || order == 12) {
				return false;
			}
			if (key == "reminder" && type == "lead" && !updateData.reminder) {
				this.$emit("openReminder", updateData);
				return false;
			}
			if (key == "reminder" && type == "lead" && updateData.reminder) {
				this.$emit("openReminder", updateData);
				return false;
			}
			/* if (order == 7 && type == "lead" && updateData.reminder) {
				this.$emit("openReminderlist", id);
				return false;
			} */
			if (key == "remark" && (type == "lead" || type == "customer")) {
				this.openRemark(updateData, type);
				return false;
			}

			if (
				this.detailRoute == "leads-detail" ||
				this.detailRoute == "task-detail" ||
				this.detailRoute == "meeting-detail"
			) {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: this.detailRoute,
					params: { id },
					query: { ...filterQuery, t: new Date().getTime(), viewType: false /* page  :this.page, */ },
				});
			} else if (this.isDialogDetail) {
				this.$emit("open-detail-dialog", id);
			} else {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: this.detailRoute,
					params: { id },
					query: { ...filterQuery, t: new Date().getTime(), viewType: false /* page  :this.page, */ },
				});
			}
		},
		routeToUpdate(id) {
			if (this.isDrawerDetail) {
				this.$emit("open-drawer", id);
			} else if (this.dialogUpdate) {
				this.$emit("open-dialog", id);
			} else {
				this.$router.push({
					name: this.updateRoute,
					params: { id },
					query: { t: new Date().getTime(), page: this.page },
				});
			}
		},

		deleteClear() {
			this.deleteDialog = false;
			this.deleteID = null;
			this.deleteURL = null;
			this.deleteText = null;
		},
		deleteRow() {
			this.deleteButton = true;
			ApiService.delete(this.deleteURL)
				.then(() => {
					this.$emit("reload:content");
					this.deleteClear();
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.deleteButton = false;
				});
		},
		deleteConfirm({ id, barcode }) {
			this.deleteDialog = true;
			this.deleteID = id;
			this.deleteURL = this.deleteEndpoint + id;
			this.deleteText = barcode;
		},
		getValue(td, key) {
			const key_array = key.split(".");
			if (key_array.length > 1) {
				if (key_array[3]) {
					return td[key_array[0]][key_array[1]][key_array[2]][key_array[3]];
				}
				if (key_array[2]) {
					return td[key_array[0]][key_array[1]][key_array[2]];
				}
				if (key_array[1]) {
					return td[key_array[0]][key_array[1]];
				}
				if (key_array[0]) {
					return td[key_array[0]];
				}
			}
			return td[key] || "-";
		},
		updateSelected() {
			if (this.selectedRows && isArray(this.selectedRows)) {
				const selectedRowsLength = toSafeInteger(this.selectedRows.length);
				const tbodyLength = toSafeInteger(this.tbody.length);
				if (selectedRowsLength > 0) {
					if (!this.checkbox) {
						this.checkbox = true;
					}
					this.indeterminate = false;
					if (selectedRowsLength != tbodyLength) {
						this.indeterminate = true;
					}
				} else {
					this.selectedRows = [];
					this.checkbox = false;
					this.indeterminate = false;
				}
				this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
			} else {
				this.$store.commit(SET_SELECTED_ROWS, []);
			}
		},
		getCustomId(barcode) {
			if (barcode) {
				let myString = barcode;
				let lastThree = myString.slice(-3);
				return lastThree;
			}
		},
		selectAll() {
			this.selectedRows = [];
			if (this.checkbox) {
				if (this.type == "leave") {
					for (let i = 0; i < this.tbody.length; i++) {
						/* let status = objectPath.get(this.tbody, `${i}.status`); */
						if (this.type == "leave" && this.tbody[i].status != "Rejected") {
							this.selectedRows.push(this.tbody[i].id);
						}
					}
				} else {
					for (let i = 0; i < this.tbody.length; i++) {
						this.selectedRows.push(this.tbody[i].id);
					}
				}
			}
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		changeStatus(status) {
			return replace(status, "-", " ");
		},
		firstChar(row) {
			return row.charAt(0);
		},
	},
	beforeMount() {
		const { query } = this.$route;
		this.query = query;
	},
	mounted() {
		/* this.getSettings(); */
		// Now this can get from listing mixing / store / mapGetters(["settings"])
		/* this.viewType = this.$route.query.viewType; */
	},
	components: {
		Chip,
		Dialog,
		FullDialog,
		ShowValue,
		TableDateTime,
		AllNotesTab,
		ImageTemplate,
		MeetingApprovalDialog,
		//	AddDialog
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
			"moduleSettings",
		]),
		taskStatusColor: function () {
			return function name(row, type) {
				if (type == "source") {
					return "blue--text";
				}
				if (type == "product_type") {
					return "green--text";
				}
				if (type == "status") {
					return "orange--text";
				}
				if (type == "opportunity") {
					return row.opportunity == "high-chance"
						? "orange--text"
						: row.opportunity == "low-chance"
						? "cyan--text"
						: row.opportunity == "50-50"
						? "green--text"
						: "blue--text";
				}
			};
		},
		taskStatusBorder: function () {
			return function name(row, type) {
				if (type == "source") {
					return "border:1px solid #2196F3";
				}
				if (type == "product_type") {
					return "border:1px solid green";
				}
				if (type == "status") {
					return "border:1px solid orange";
				}
				if (type == "opportunity") {
					return row.opportunity == "high-chance"
						? "border:1px solid orange"
						: row.opportunity == "low-chance"
						? "border:1px solid cyan"
						: row.opportunity == "50-50"
						? "border:1px solid green"
						: "border:1px solid gray";
				}
			};
		},
	},
};
</script>
